// DrawingComponent.jsx
import React, { useEffect, useState } from 'react';
import * as turf from '@turf/turf';
import rectangleGrid from '@turf/rectangle-grid';
let solarpanels = [];
const CopyDrawingRectComponent = ({ map, setPolygonData, mainpolypath1, setSolarPotential1, selectedRoof, 
  onSelectedRoofChange,accumulatedValues1,copyonPanelCountChange,onOrientationChange, onPitchChange, }) => {
    // accumulatedValues1 = accumulatedValues1
  const [drawingEnabled, setDrawingEnabled] = useState(true);
  const [edgeDistance, setEdgeDistance] = useState(    selectedRoof.pitchDegrees > 0 && selectedRoof.pitchDegrees < 1 ? 0.6 : 0.3);
  const [selectedPanelPower, setSelectedPanelPower] = useState(500); 
  const handleedgesideInputChange=(event)=>{
    setEdgeDistance(event.target.value)
  }


  const [cellWidth, setCellWidth] = useState(1.134);
  const [cellHeight, setCellHeight] = useState(1.755);
 // const [PanelCount, setPanelCount] = useState(0)
  const [selectedOrientation, setSelectedOrientation] = useState('portrait');
  // const [dat, setDat] = useState(null);
  const [, setMaxPanelCount] = useState(0)
  const [edgeDistanceFromTop] = useState(0.3);
  const [edgeDistanceFromSides] = useState(0.3);
  const [horizontalDistanceBetweenPannel] = useState(0.02);
  const [edgeDistanceFromBottom] = useState(
    selectedRoof.pitchDegrees > 0 && selectedRoof.pitchDegrees < 1 ? 0.6 : 0.3
  );
  const [verticalDistanceBetweenPannel] =useState(
    selectedRoof.pitchDegrees > 0 && selectedRoof.pitchDegrees < 1 ? 0.6 : 0.02
  );
 
  const panelOptions = {
    "500wc": { height: 2.094, width: 1.134 ,power:500},
    "aeg50": { height: 2.094, width: 1.134 ,power:500 },
    "375wc": { height: 1.646, width: 1.140 ,power:375 },
    "g375wc": { height: 1.755, width: 1.038 ,power:375 },
    "d375wc": { height: 1.755, width: 1.038 ,power:375 },
    "r375wc": { height: 1.755, width: 1.038 ,power:375 },
    "ad375wc": { height: 1.755, width: 1.038 ,power:375 },
    "c410": { height: 1.722, width: 1.134 ,power:410 },
  };

  const handleOrientationChange = (e) => {
    const newOrientation = e.target.value;
    setSelectedOrientation(newOrientation);
    // Swap cellWidth and cellHeight values
    const celloldwidth = cellWidth
    const celloldHeight = cellHeight
    setCellWidth(celloldHeight);
    setCellHeight(celloldwidth);
  };

  const removePolyMarker = (name) => {
    const data = []
    for (let sl in solarpanels) {
      if (solarpanels[sl].title === name) {
        solarpanels[sl].setMap(null)
      }
      else {
        data.push(solarpanels[sl])
      }
    }
    solarpanels = data
    //setPanelCount(solarpanels.length)
    copyonPanelCountChange(solarpanels.length);
  }

  useEffect(() => {
    

    return () => {
  
    };
  }, [map, drawingEnabled, setSolarPotential1, mainpolypath1, setPolygonData, onSelectedRoofChange]);

  const toggleDrawing = () => {
    setDrawingEnabled(!drawingEnabled);
    if (drawingEnabled) {
      if (solarpanels.length > 0) {
         for (let sl in solarpanels) {
           solarpanels[sl].setMap(null)
         }
         solarpanels = []
       }
       const paths = mainpolypath1.getPath().getArray().map((latLng) => [latLng.lng(), latLng.lat()]);
       paths[paths.length] = paths[0]
       const line = turf.lineString(paths)
      //  console.log(edgeDistance)
       const tmainbuffer =turf.buffer(line, edgeDistance , { units: 'meters' })
       const newpoly =  turf.polygon([ tmainbuffer.geometry.coordinates[1]])
      //  console.log(newpoly)
       const maskcenter = turf.centroid(newpoly)
       const rotateoptions = { pivot: maskcenter };
       const rotatedpoly= turf.transformRotate(newpoly, (selectedRoof.azimuthDegrees) * -1, rotateoptions)
       var options = { units: 'meters', mask: rotatedpoly };
       const dat = rectangleGrid(turf.bbox(rotatedpoly), cellWidth, cellHeight, options);
       for (let i = 0; i < dat.features.length; i++) {
         const rotatedPoly = turf.transformRotate(dat.features[i], Number(selectedRoof.azimuthDegrees), rotateoptions)

         if (turf.booleanIntersects(turf.buffer(turf.lineString(paths), edgeDistance , { units: 'meters' }), rotatedPoly) === false) {
           const polygonCoordinates = rotatedPoly.geometry.coordinates[0].map(coord => new window.google.maps.LatLng(coord[1], coord[0]));
          const tile = 'solapane' + i
           // Create a polygon using the coordinates
           const polygon = new window.google.maps.Polygon({
             paths: polygonCoordinates,
             map: map,
             title: tile,
             fillColor: '#091124',  // Set the fill color (replace with your desired color)
             fillOpacity: 1,
             strokeColor: '#3e80ec',
             strokeWeight: 1,
             zIndex: 5
           });
           map.addListener(polygon, 'click', function (event) {
             alert(this.indexID);
           });
           polygon.addListener('click', (event) => {
             removePolyMarker(tile)
             // infoWindow.close();
             // infoWindow.setContent(`Pin dropped at: ${position.lat()}, ${position.lng()}`);
             // infoWindow.open(marker.map, marker);
           });
           polygon.setMap(map)
           solarpanels.push(polygon)
         }
       }
      // setPanelCount(solarpanels.length);
       copyonPanelCountChange(solarpanels.length);

       const widthRectangle2 = accumulatedValues1[1] - edgeDistanceFromSides * 2; // Remove the 2 sides distance
       const heightRectangle2 = accumulatedValues1[0] - edgeDistanceFromTop - edgeDistanceFromBottom;
       // Calculation with Portrait orientation
       // Calculation with Landscape orientation
       if (selectedOrientation === 'portrait') {
         const maxCountVerticalPortrait = Math.floor(heightRectangle2 / (verticalDistanceBetweenPannel + cellHeight));
         const maxCountHorizontalPortrait = Math.floor(widthRectangle2 / (horizontalDistanceBetweenPannel + cellWidth));
      
         const maxCountPortrait = maxCountVerticalPortrait * maxCountHorizontalPortrait;

         setMaxPanelCount(maxCountPortrait)
       } else {
         const MaxCountVerticalLandscape = Math.floor(heightRectangle2 / (verticalDistanceBetweenPannel + cellHeight));
         const MaxCountHorizontalLandscape = Math.floor(widthRectangle2 / (horizontalDistanceBetweenPannel + cellWidth));
         const MaxCountLandscape = MaxCountVerticalLandscape * MaxCountHorizontalLandscape;
         setMaxPanelCount(MaxCountLandscape)
       }
      
   }
   else{
     if (solarpanels.length > 0) {
       for (let sl in solarpanels) {
         solarpanels[sl].setMap(null)
       }
       solarpanels = []
     }
   }
  };

  const handleInputChange = (event) => {
    const newValue = event.target.value;
    onSelectedRoofChange(newValue);
  };


  var PowerPerPannel = selectedPanelPower ; // Per panel : 410° kWp
  var NumberOfPannelsOnFace = solarpanels.length;
  var SolarPowerOfµFace = NumberOfPannelsOnFace * PowerPerPannel / 1000;
  var SolarProductionPerYear = selectedRoof.solarproduction * SolarPowerOfµFace;// SECOND DATA TO DISPLAY ON THE FACE WHEN PANNELS DRAWN
 return (
    <div>
      <div className="container">
        <button onClick={toggleDrawing}>
        {drawingEnabled ? 'Remplir' : 'Effacer'}
        </button>
        <div className="form-group mb-2">
          <label htmlFor="shadingPercentageDropdown" className="form-label">Sélection du type de panneaux:</label>
          <select
            className="form-control"
            id="shadingPercentageDropdown"
            onChange={(e) => {
              const selectedPanel = e.target.value;
              const panelDimensions = panelOptions[selectedPanel];
              if (panelDimensions) {
                setSelectedPanelPower(panelDimensions.power);
                if (selectedOrientation === 'portrait') {
                  setCellWidth(panelDimensions.width);
                  setCellHeight(panelDimensions.height);
                } else {
                  setCellWidth(panelDimensions.height);
                  setCellHeight(panelDimensions.width);
                }
              }
            }}
          >
            <option value="500wc">Premium / Bourgeois Global 500 wc</option>
            <option value="aeg50"> Premium / AEG 50</option>
            <option value="375wc"> Premium / DualSun  375 wc</option>
            <option value="g375wc">Premium / Bourgeois global 375wc</option>
            <option value="d375wc">Access / Dmegc 375 wc</option>
            <option value="r375wc"> Access / Recom 375 wc</option>
            <option value="ad375wc">Access / Dmegc 375 wc</option>
            <option value="c410"> Access / CECEP 410</option>
            {/* Add other percentage options as needed */}
            {/* Add other percentage options as needed */}
          </select>
        </div>

        <div className="form-group mb-2">
          <label htmlFor="frameTypeDropdown" className="form-label">Pan sélectionné</label>
          <input
            disabled
            type="text"
            className="form-control"
            placeholder="Enter a location"
            value={selectedRoof ? selectedRoof.name : ''}
            onChange={handleInputChange} // Call the function to update the parent's state
          />
        </div>

        <div className="form-group mb-2">
          <label htmlFor="shadingPercentageDropdown" className="form-label">Type de toit</label>
          <select disabled
            className="form-control"
            id="shadingPercentageDropdown"
            onChange={(e) => {
              // const selectedShadingPercentage = e.target.value;
              // Handle the selected shading percentage
            }}
          >
            {selectedRoof && selectedRoof.pitchDegrees > 1 ? (
              <>
                <option value="pitch">Incliné</option>
                
                {/* Add other pitch options as needed */}
              </>
            ) : (
              <option value="flat">Flat</option>
            )}
          </select>
        </div>

        <div className="form-group mb-2">
          <label htmlFor="shadingPercentageDropdown" className="form-label">Type d’orientation vs le toit</label>
          <select
            className="form-control"
            id="shadingPercentageDropdown"
            onChange={(e) => {
              // const selectedShadingPercentage = e.target.value;
              // Handle the selected shading percentage
            }}
          >
            <option value="RoofOrientation">Parallèle au toit </option>
            <option value="RoofOrientation">Orienté Sud</option>

            {/* Add other percentage options as needed */}
          </select>
        </div>

        {/* Repeat the structure for other dropdowns with unique htmlFor and id attributes */}

        <div className="form-group mb-2">
          <label htmlFor="shadingPercentageDropdown" className="form-label">Orientation des panneaux</label>
          <select
            className="form-control"
            id="shadingPercentageDropdown"
            value={selectedOrientation}
            onChange={(e) => {
              // const selectedShadingPercentage = e.target.value;
              handleOrientationChange(e)
              // Handle the selected shading percentage
            }}
          >
            <option value="portrait">Portrait</option>
            <option value="landscape">Paysage</option>
            {/* Add other percentage options as needed */}
          </select>
        </div>

        <div className="row">
         
          <div className="col-sm mb-2">
            <label className="form-label">Distance du bord</label><br />
            <input
              type="number"
              className="form-control"
              value={edgeDistance}
              onChange={handleedgesideInputChange}
            />
          </div>
         
        </div>

        <div className="row">
       
          <div className="col-sm mb-2">
            <label className="form-label">Nbr de panneaux:</label><br />
            <span>{solarpanels.length}</span>
          </div>
          <div className="col-sm mb-2">
            <label className="form-label">Production (kWh / an):</label><br />
            <span>{SolarProductionPerYear.toFixed(0)} kWh</span>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CopyDrawingRectComponent;