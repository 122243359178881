import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import GoogleMapReact from 'google-map-react';

import html2canvas from 'html2canvas';

const Wrapper = styled.main`
 // width: 75%;
  height: 100%;
  // position:absolute;
  position: relative;
  overflow: hidden;
`;
const GoogleMap = ({ id, children, ...props }) => (
  <Wrapper>
    <GoogleMapReact
      id={"map"}
      options={{
        mapTypeId: 'satellite',
        tilt: 10,
        heading: 270,
        mapId: "90f87356969d889c",
        rotateControl: true,
        streetViewControl: true,
      }}

      {...props}
    >
      {children}
    </GoogleMapReact>
  </Wrapper>
  
);

GoogleMap.propTypes = {
  id: PropTypes.string, // Optional id prop
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
};

GoogleMap.defaultProps = {
  id: 'map', // Default id if not provided
  children: null,
};

export default GoogleMap;