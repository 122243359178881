import React, { Component } from 'react';
import { Form, InputGroup ,Spinner } from 'react-bootstrap';
import '../App.css'
import * as PlatForm from 'platform'
import Autocomplete from "react-google-autocomplete";

class AutoCompleteMain extends Component {
  constructor(props) {
    super(props);
    this.state = {
      address: '',
      apiEndPoint: 'https://maps.googleapis.com/maps/api/js?key=AIzaSyA_Ql5Bfqu0sKMbv5-PtCHSN4klSBactxQ&libraries=places,geometry,drawing',
      predication:[],
      postalCode: '',
      apiLoaded:false,
      fullPlaceHolder:false

    };
    this.autocompleteRef = React.createRef();
  }


  componentDidMount(){
  }

  handleInputChange = () =>{
    this.props.parentHandleInput({address:this.state.address,placeSelected:false})
  }

  getPlaceDetails(placeId, callback) {
    const map = new window.google.maps.Map(document.createElement("div"));
    const service = new window.google.maps.places.PlacesService(map);

    service.getDetails({
        placeId: placeId
    }, (place, status) => {
      if (status === window.google.maps.places.PlacesServiceStatus.OK && this.autocompleteRef.current) {
        this.autocompleteRef.current.value = place.formatted_address;
        this.placeSelectedHandler(place)
    }
    });
  }

  componentDidMount() {
    const platform = PlatForm.os.family;
    console.log(platform)
    if(platform === 'Windows'){
      this.setState({fullPlaceHolder:true})
    }
    console.log(platform)
    const url = new URL(window.location.href);

    const params = new URLSearchParams(url.search);

    const placeId = params.get('placeId')
    if (placeId != null) {
      this.getPlaceDetails(placeId, (place) => {
        this.setState({ address: place })
        this.handleInputChange(place)
        this.props.parentHandleInput({latLong:[place.geometry.location.lat(), place.geometry.location.lng()],placeSelected:true, address: '', postalCode: ''})
      })
    }
  }

  placeSelectedHandler(place) {
    this.setState({ address: place })
    this.handleInputChange(place, this.autocompleteRef)
    let postalCode = ''
    if (place.address_components) {
      place.address_components.forEach(component => {
        if (component.types.includes("postal_code")) {
          postalCode = component.long_name
        }
      });
    }
    this.props.parentHandleInput({latLong:[place.geometry.location.lat(), place.geometry.location.lng()],placeSelected:true, address: place, postalCode: postalCode})
  }
 

  render() {
    return (
      <>

          <Autocomplete
            apiKey={'AIzaSyDUbRNlM1l_R_sIyysLcWUVvApaMtuKbXc'}
            // style={{ width: "100%" }}
            id='autoCompleteInput'
            className="inputbox"
            ref={this.autocompleteRef}
            placeholder={this.state.fullPlaceHolder ? 'Entrez votre adresse pour localiser votre maison par satellite' :'Entrez votre adresse pour localiser votre maison' }
            onChange={(e) => this.handleInputChange(e)}
            onPlaceSelected={(place) => this.placeSelectedHandler(place)}
            options={{
              types: ["geocode", "establishment"],
              componentRestrictions: { country: 'fr' } 
            }}
          />
        
      </>
    );
  }
}

export default AutoCompleteMain;
