// DrawingComponent.jsx
import React, { useEffect,forwardRef,useState, useImperativeHandle } from 'react';
var polygon1;
const CopyDrawingComponent = forwardRef(({ map,setSolarPotential2,getmainPolygon1 },ref) => {
  const [drawingEnabled, setDrawingEnabled] = useState(false);
  const calculateDistanceBetweenPoints = (point1, point2) => {
    // const latLng1 = new window.google.maps.LatLng(point1.lat, point1.lng);
    // const latLng2 = new window.google.maps.LatLng(point2.lat, point2.lng);
    return window.google.maps.geometry.spherical.computeDistanceBetween(point1, point2);
  }; 
  useImperativeHandle(ref, () => ({
    removeborder,
  }));
 
 
  useEffect(() => {
    let drawingManager;
    if (drawingEnabled) {
      if(polygon1){
        polygon1.setMap(null)
      }
      drawingManager = new window.google.maps.drawing.DrawingManager({
        drawingMode: window.google.maps.drawing.OverlayType.POLYGON,
        drawingControl: false,
        polygonOptions: {
          editable: false,
        },
      });
      drawingManager.addListener('polygoncomplete', function (e) {
        toggleDrawing();
        e.setMap(null);
        getmainPolygon1(e)
       
        //polygon1 =   e
       // polygon1.setMap(map)
        const paths = e.getPaths().getArray();
        const totalDistance = calculateTotalDistance(paths);
        totalDistance.push(window.google.maps.geometry.spherical.computeArea(e.getPath()))
        setSolarPotential2(totalDistance)
        drawingManager.setMap(null);
      });
      drawingManager.setMap(map);
    }

    const calculateTotalDistance = (paths) => {
      // let totalDistance = 0;
      var  totalDistance1 = [];
      for (let i = 0; i < paths.length; i++) {
        const path = paths[i].getArray();
        for (let j = 1; j < path.length; j++) {
          const distanceBetweenPoints = calculateDistanceBetweenPoints(path[j - 1], path[j]);
          
          // totalDistance += distanceBetweenPoints; 
          totalDistance1.push(distanceBetweenPoints)
        }
      }
      // Set the solar potential using the provided prop function
      
      return totalDistance1;
    };

    return () => {
      if (drawingManager) {
        drawingManager.setMap(null);
      }
    };
  }, [map, drawingEnabled,setSolarPotential2]);

  const toggleDrawing = () => {
    setDrawingEnabled(!drawingEnabled);
  };

  const removeborder=()=>{
    polygon1.setMap(null)
  }

  return (
    <div>
      <button onClick={toggleDrawing}>
      {drawingEnabled ? 'Activer le dessin ' : 'Dessin désactivé'}
      </button>
    </div>
  );
});

export default CopyDrawingComponent;