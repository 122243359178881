import React, { Component } from 'react'
// components:
import Spinner from 'react-bootstrap/Spinner';
// examples:
import GoogleMap from './GoogleMap';
import AutoComplete from './autocomplete';
import DrawingComponent from './DrawingComponent';
import DrawingRectComponent from './DrawingRectComponent';
import CopyDrawingRectComponent from './copyDrawingRectComponent';
import CopyDrawingComponent from './copyDrawingComponent';
import { Slider } from '@mui/material';
import {Row , Col} from 'react-bootstrap'
import Modal from 'react-bootstrap/Modal'
import houseRofToit from '../assets/image/toiture-plate.a9bb359b.svg'
import houseSvg from '../assets/image/houseSvg.svg'
import appartmentSvg from '../assets/image/appartement.svg'
import smallFace from '../assets/image_icon_Screen/smallFace.svg'
import mediumFace from '../assets/image_icon_Screen/mediumFace.svg'
import bigFace from '../assets/image_icon_Screen/bigFace.svg'
import verybigFace from '../assets/image_icon_Screen/verybigFace.svg'
import questionsvg from '../assets/image/question.svg'

import SouthFace from '../assets/image_icon_Screen/SouthFace.svg'
import SouthEastFace from '../assets/image_icon_Screen/SouthEastFace.svg'
import WestEastFace from '../assets/image_icon_Screen/WestEastFace.svg'
import DontKnowFace from '../assets/image_icon_Screen/DontKnowFace.svg'
import FlatRoof from '../assets/image_icon_Screen/FlatRoof.svg'
import NorthFace from '../assets/image_icon_Screen/NorthFace.svg'


// consts
import LOS_ANGELES_CENTER from '../const/constant';
import { Form, Button, ButtonGroup, ToggleButton } from 'react-bootstrap';
import solarImage from '../assets/image/solar.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding, faHouse, faPersonShelter } from '@fortawesome/free-solid-svg-icons';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import  '../App.css'
import './indexAutoSome.scss'
import { GoogleApiWrapper } from 'google-maps-react';
import AutoCompleteMain from './AutoCompleteMain';
import { Stepper, Step, StepLabel, Typography, Paper } from '@mui/material';
// import slider from './slider';
// import Map from './leafletmap';
// import html2canvas from 'html2canvas';


var mapview, mapplace,marker, polygon,drawingManager, obstaclepolygon, polygonline, obstaclepolygonline, polygon1, polygonline1;
const formatValue = (value, decimalPlaces) => {
  return value !== undefined ? value.toFixed(decimalPlaces) : '';
};

const theme = createTheme({
  palette: {
    primary: {
      main: '#FF0000', // Change this to your desired primary color
    },
    secondary: {
      main: '#f50057', // Change this to your desired secondary color
    },
  },
});

const sliderTheme = createTheme({
  components: {
    MuiSlider: {
      styleOverrides: {
        rail: {
          backgroundColor: '#FF0000', // Track color
        },
        thumb: {
          backgroundColor: '#FF0000', // Thumb color
        },
        track:{
          backgroundColor: '#FF0000', 
          borderColor:'#FF0000'// Track color
        },mark:{
          color:'#FF0000'
        },
        valueLabel:{
          backgroundColor: '#FF0000',
          fontSize:'18px'
        }
      },
    },
  },
});


var roofsegmarker = []
var roofsegmarker1 = []
const type= 'simulator';
class Autocomplete extends Component {

  constructor(props) {
    super(props);
    this.childRef = React.createRef();
    // const [isChecked, setIsChecked] = useState(false);
      
   
    this.state = {
      mapApiLoaded: false,
      defaultMarker:null,
      mapInstance: null,
      thirdStepCopy:false,
      mapApi: null,
      secondLoader:false,
      maxRoof : null,
      isManual:false,
      atleastOneSelected:false,
      activeButtonRow1: null,
      orientationManual:0,
      inclinaisonManual:0,
      areaMeters2Manual:0,
      activeButtonRow2: null,
      fourthStepShadow:false,
      places: [],
      drawingMarkerEnabled:null,
      showModal:false,
      solarPotential: props.solarPotential || null,
      solarPotential1: props.solarPotential || null,
      selectedRoof: null,
      selectedRoof1: null,
      buttonVariant: 'outline-danger',
      mapRef: React.createRef(),
      accumulatedValues: [],
      obstacleAccumulatedValues: [],
      windowHeight: window.innerHeight - 80,
      // accumulatedValues1: [],
      accumulatedValues1: [0, 0],
      faceProductions: [],
      loading: false,
      latlong:[],
      panelCount: 0,
      panelPower: 0,
      pannelSurface: 0,
      copypanelCount: 0,
      placeSelected:false,
      address: '',
      manualProductible: 0,
      // Initial value of address
      // accumulatedValues1: [0, 0, 0],
      OrientationChange: '',
      InclinaisonChang: '',
      step: 0,
      hideMap: true,
      nameLead: '',
      map:undefined,
      surnameLead: '',
      isOwner: 'Maison',
      isFormSubmit: false,
      setIsChecked: '',
      faceNumber: '',
      faceProductible: '',
      faceSurface: '',
      latLong:[],
      faceOrientation: '',
      faceInclinaison: '',
      monthlyInvoice: 250,
      email: '',
      phone: '',
      address1: '',
      projectTiming: '',
      isFirstLine:false,
      emailError:'',
      isSecondLine:false,
      isThirdLine:false,
      isChecked: false,
      isChecked1: false,
      valueLow: false,
      idHash: null,
      // drawingMarkerEnabled: false,
      postalCode: '',
      recommendedSimulation: null,
      minimumSimulation: null,
      marks:
        [
          {
            value: 100,
          },   {
            value:40,
            label: '40€'
          },  {
            value: 500,
            label: '500€'
          }],
          labelArray:[`
Réduisez votre facture d’électricité jusqu’à 50%
Votre estimation d’économies en 4 étapes !`,
          "Cliquez sur le toit de votre maison", 
`Votre maison est éligible !
Voici le pan solaire optimum de votre toiture` ,[
`Nous n’avons pas pu analyser votre toiture !
Donnez-nous ses caractéristiques :`
          ]

        ],
        listOfLoadingPoints:[
          [
            "Analyse de votre toiture",
            "Identification du meilleur pan",
            "Calcul du productible solaire "

          ],
          [
            "Calcul du rendement optimum",
            "Calcul du meilleur prix",
            "Calcul de vos économies"
          ]
        ],
        heightOfReactangle:[
          4,8,10,20
        ],
        widthOfReactangle:[
          4,4.5,5,15
        ],
        orientationSelection:[
          0,45,90,175,0,30
        ],
        inclinaisonSelection:[
          20,20,20,20,0,20
        ]


        }

        
        
    
  }
 
  

  setMonthlyInvoice = async (e) => {
    const monthlyInvoice = parseInt(e.target.value, 10); // Convert value to an integer

    // Check if monthlyInvoice is below 100
    const valueLow = monthlyInvoice < 100;
  
    // Update the state
    this.setState({
      monthlyInvoice: monthlyInvoice,
      valueLow: valueLow
    });
  
  }

  validateEmail = (email) => {
    // Regular expression for validating email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  
    // Validate email syntax
    if (name === 'email') {
      const isValidEmail = this.validateEmail(value);
      this.setState({ emailError: isValidEmail ? '' : 'Please enter a valid email address' });
    }
    // console.log(this.state.emailError , value,name);
  };
  


  apiHasLoaded = (map, maps) => {
    mapview = map;
    // document.getElementById('custom-map-id').dataset.mapLoaded = 'true';
    const mapOptions = {
      mapTypeId: 'satellite',
      tilt: 10,
      maxZoom: 24,
      heading: 270,
      mapId: "90f87356969d889c",
      rotateControl: true,
      streetViewControl: false,
      fullscreenControl: false 
    };
    map.setOptions(mapOptions);
    this.setState({
      mapApiLoaded: true,
      mapInstance: map,
      mapApi: maps,
     // step: 0,
    });
  };

  // drawaddMarker = () => {
  //   if(this.state.drawingMarkerEnabled){
  //     drawingManager.setMap(null);
  //   }
  //   else{
  //     drawingManager = new window.google.maps.drawing.DrawingManager({
  //       drawingMode: window.google.maps.drawing.OverlayType.MARKER,
  //       drawingControl: false,
  //       polygonOptions: {
  //         editable: false,
  //       },
  //     });
  
  //     drawingManager.addListener('markercomplete', (e) => {
  //       e.setMap(null);
  //       drawingManager.setMap(null);
  //       this.setState(prevState => ({
  //     drawingMarkerEnabled: !prevState.drawingMarkerEnabled,
  //   //  buttonClicked: true
  //   }));
  //       // let mapview = e.mapview; // Assuming mapview is defined elsewhere in your code
  //       mapplace = e.position
  //       mapview.setCenter(e.position);
  //       mapview.setZoom(20);
  //       if (marker) {
  //         marker.setMap(null)
  //       }
  //       // Get the address of the location
  //       marker = new window.google.maps.Marker({
  //         position: e.position,
  //         map: mapview,
  //         name: 'locationmarker',
  //         title: 'location',
  //         draggable: true,
  //       });
  //       // Add dragend event listener to the marke
  
  
  //     });
  //     drawingManager.setMap(mapview);
  //   }
  //   this.setState(prevState => ({
  //     drawingMarkerEnabled: !prevState.drawingMarkerEnabled,
  //   //  buttonClicked: true
  //   }));
    
    
  // }


  addPlace = async (place) => {
     const address = place.name;
    //  this.setState({ address });
     this.setState({ address });
    mapplace = place.geometry.location
    // if (!place || !place.name) return;
    if (!place.geometry) return;
    if (place.geometry.viewport) {
      mapview.setCenter(place.geometry.location);
      mapview.setZoom(20);
    } else {
      mapview.setCenter(place.geometry.location);
      mapview.setZoom(20);
    }
    if (marker) {
      marker.setMap(null)
    }
    marker = new window.google.maps.Marker({
      position: place.geometry.location,
      mapview,
      name: 'locationmarker',
      title: place.name,
      gmpDraggable: true,
    });
    marker.setMap(mapview)
    this.setState({ places: [place] });
  };


  isPolygonClockwise = (coords) => {
    let sum = 0;
    for (let i = 0; i < coords.length; i++) {
      let nextIndex = (i + 1) % coords.length;
      sum += (coords[nextIndex].lng() - coords[i].lng()) * (coords[nextIndex].lat() + coords[i].lat());
    }
    return sum > 0;
  }
  getobstaclePolygon = async (obstaclepath) => {

    if (obstaclepolygon) {
      obstaclepolygon.setMap(null)
      obstaclepolygonline.forEach(line => {
        line.setMap(null);
      })
    }
    obstaclepolygonline = [];
    this.setState({ obstaclepolypath: obstaclepath })
    obstaclepolygon = obstaclepath
    obstaclepolygon.setMap(mapview)
    // const polygonrotation = this.isPolygonClockwise(newpath)
  }

  handleClose = () => this.setState({ showModal: false });

  getmainPolygon = async (path) => {
    if (polygon) {
      polygon.setMap(null)
      polygonline.forEach(line => {
        line.setMap(null);
      })
    }
    polygonline = [];
    this.setState({ mainpolypath: path })

    polygon = path
    const newpath = polygon.getPath().getArray()
    const polygonrotation = this.isPolygonClockwise(newpath)
    for (let i = 0; i < newpath.length; i++) {
      const coords =
        (i === (newpath.length - 1)) ? [newpath[i], newpath[0]] :
          [newpath[i], newpath[i + 1]];
      const line = new window.google.maps.Polyline({
        path: coords,
        strokeColor: "#FF0000",
        strokeOpacity: 1.0,
        strokeWeight: 3,
        geodesic: true,
        map: mapview
      });

      line.addListener('click', () => {
        const lncoords = line.getPath().getArray()
        const angle = (polygonrotation) ? window.google.maps.geometry.spherical.computeHeading(lncoords[0], lncoords[1]) + 270 :
          window.google.maps.geometry.spherical.computeHeading(lncoords[0], lncoords[1]) + 90
        var newangle = angle - 180
        this.setState((prevState) => ({
          selectedRoof: {
            ...prevState.selectedRoof,
            azimuthDegrees: (newangle).toFixed(2),
          },
        }));
        polygonline.forEach(neline => {

          neline.setOptions({
            strokeColor: (neline === line) ? '#ffb300' : '#ff0000'
          });

        })
      });
      polygonline.push(line)
    }
  }

  getmainPolygon1 = async (path) => {
    this.setState({ mainpolypath1: path })
    if (polygon1) {
      polygon1.setMap(null)
      polygonline1.forEach(line => {
        line.setMap(null);
      })
    }
    polygon1 = path
    polygonline1 = [];
    const newpath = polygon1.getPath().getArray()
    const polygonrotation = this.isPolygonClockwise(newpath)
    for (let i = 0; i < newpath.length; i++) {
      const coords =
        (i === (newpath.length - 1)) ? [newpath[i], newpath[0]] :
          [newpath[i], newpath[i + 1]];
      const line = new window.google.maps.Polyline({
        path: coords,
        strokeColor: "#FF0000",
        strokeOpacity: 1.0,
        strokeWeight: 3,
        geodesic: true,
        map: mapview
      });

      line.addListener('click', () => {
        const lncoords = line.getPath().getArray()
        const angle = (polygonrotation) ? window.google.maps.geometry.spherical.computeHeading(lncoords[0], lncoords[1]) + 270 :
          window.google.maps.geometry.spherical.computeHeading(lncoords[0], lncoords[1]) + 90
        var newangle = angle - 180
        this.setState((prevState) => ({
          selectedRoof1: {
            ...prevState.selectedRoof1,
            azimuthDegrees: (newangle).toFixed(2),
          },
        }));
        polygonline1.forEach(neline => {

          neline.setOptions({
            strokeColor: (neline === line) ? '#ffb300' : '#ff0000'
          });

        })
      });

      polygonline1.push(line)
    }
  }

  addMarker(segment, label,isMax) {
    if(this.state.defaultMarker) this.state.defaultMarker.setMap(null)
    const loc = { lat: segment.center.latitude, lng: segment.center.longitude }

    if(isMax){
      const marker = new window.google.maps.Marker({
        position: loc,
        title: segment.name,
        label: label,
      });
      // this.setState({maxRoof:segment})
      marker.addListener('click', () => {
        this.setState({ selectedRoof: segment });
      });
      marker.setLabel(null)
      marker.setMap(this.state.map)
      roofsegmarker.push(marker)
    }
    // else{
    //   marker.setIcon({
    //     path: window.google.maps.SymbolPath.CIRCLE,
    //     scale: 10, // Adjust the scale as needed
    //     fillOpacity: 1,
    //     fillColor:"black",
    //     strokeColor: 'black', // You can change the stroke color if needed
    //     strokeWeight: 2 // You can change the stroke weight if needed
    //   })
    // }
   

    const marker1 = new window.google.maps.Marker({
      position: loc,
      title: segment.name,
      label: label,
      icon: {
        path: window.google.maps.SymbolPath.BACKWARD_OPEN_ARROW,
        scale: 10, // Adjust the scale as needed
        fillOpacity: 1,
        strokeColor: '#AA3A22', // You can change the stroke color if needed
        strokeWeight: 2 // You can change the stroke weight if needed
      }
    });

    
    marker1.addListener('click', () => {
      this.setState({ selectedRoof1: segment });
    });
    marker1.setMap(null)
    roofsegmarker1.push(marker1)
  }

  removeMarker(name) {
    roofsegmarker.forEach(item => {
      if (item['title'] !== name) {
        item.setMap(null)
      } else {
        item.setMap(mapview)
      }
    })
  }
  removeMarker1(name) {
    roofsegmarker1.forEach(item => {
      if (item['title'] !== name) {
        item.setMap(null)
      } else {
        item.setMap(mapview)
      }
    })
  }
  removeAllMarkerr() {
    roofsegmarker.forEach(item => {
      item.setMap(null)
    })
  }
  viewAllMarkerr1() {
    roofsegmarker1.forEach(item => {
      item.setMap(mapview)
    })
  }
  removeAllMarkerr1() {
    roofsegmarker1.forEach(item => {
      item.setMap(null)
    })
  }

  valuetext(value){
    return `${value}$`
  }

  loadslardata = async (isFastForwad) => {

    this.setState({ loading: true });
    setTimeout(() => {
      this.setState({isFirstLine:true})
    }, 0);

    setTimeout(() => {
      this.setState({isSecondLine:true})
    }, 400);
    setTimeout(() => {
      this.setState({isThirdLine:true})
    }, 1600);
    const apiKey = '&key=AIzaSyA_Ql5Bfqu0sKMbv5-PtCHSN4klSBactxQ';
    const response = await fetch('https://solar.googleapis.com/v1/buildingInsights:findClosest?location.latitude=' + this.state.latlong.lat+ '&location.longitude=' + this.state.latlong.lng + '&requiredQuality=HIGH' + apiKey);

    // const response2 = await fetch('https://solar.googleapis.com/v1/dataLayers:get?location.latitude=' + this.state.latlong.lat + '&location.longitude=' + this.state.latlong.lng + '&radiusMeters=50&view=FULL_LAYERS&requiredQuality=HIGH&pixelSizeMeters=0.1' + apiKey);


    try {
      setTimeout(() => {
        this.setState({ loading: false });
        this.setState({
          isFirstLine: false,
          isSecondLine: false,
          isThirdLine: false
        })

      }, 1000);

      // Start analysing the JSON in order to add the production per kWp on each face

      // const response3 = await fetch(tiffUrl  );
      // const tiffData = await response.blob();
      // // Process the GeoTIFF data here



      if (!response.ok) {
        throw new Error('Network response for buildingInsights was not ok');
      }



      if (response.ok) {
        const obj = await response.json();
        var obj1 = obj["solarPotential"];
        var panelCapacity = obj1["panelCapacityWatts"];
        // Power of 250 Wp per pannel by default in Google Solar today. Maybe it will be possible to adjust pannel power soon !
        var obj2 = obj1["roofSegmentStats"];
        var nbrOfFaces = obj2.length; // Determine the number of faces on the house
        var obj3 = obj1["solarPanelConfigs"];
        var nbrOfConfigurations = obj3.length; // Determine all the combinaisons possibles of configuration with the faces on the roof.
        var obj4 = obj3[nbrOfConfigurations - 1]; // we use the last configuration to get the information we calculate the irradiation on each face
        var obj5 = obj4["roofSegmentSummaries"];

        var loss = 0.14; // 14% system loss that needs to be used for evaluate the production on each face (and that Google don't take into account !)

        // For each face, we will add the production information : kWh per kWp
        for (var key in obj5) {
          var nbrPanel = obj5[key].panelsCount;
          var yearEnergy = obj5[key].yearlyEnergyDcKwh;
          var faceProduction = yearEnergy / nbrPanel / panelCapacity * 1000 * (1 - loss);
          // console.log("Face " + key + " Productible = " + faceProduction + " kWh / kWp");

          // Add to the roofSegmentStats object the "FaceProduction" and Max number of panel (calculated by Google on its pannel size) data which was missing to calculate the production !
          obj2[key].productible = faceProduction;
          obj2[key].maxNbrPannels = nbrPanel;
          // Example of calculation for a solar kit with 8 pannels with pannel capacity of 400 Wp
          // solar kit power is : 8 pannels X 400 Wp / 1000 = 3.2 kWp
          // console.log("Face " + key + " Production example of a 3.2 kWp solar kit = " + faceProduction * 3.2 + " kWh / year");
        }

        // ADDITIONAL CODE //
        this.sortAndFindOrder(obj2);

        // KRUNAL : this function will reorder the faces according to their power and orientation.
        // You need to display the face's pointer with the number you have in the object = > obj2[index].faceNumber


        // Prepare the Json we will send to the back with only the right information
        var payload = {
          "name": obj["name"],
          "center": obj["center"],
          "RoofSegment": obj2
        };

        // console.log(payload);

        const result = obj;
        const roofsegemnts = result.solarPotential.roofSegmentStats;
        var obj1 = result["solarPotential"];
        var panelCapacity = obj1["panelCapacityWatts"];
        var obj2 = obj1["roofSegmentStats"];
        // var nbrOfFaces = obj2.length; // Determine the number of faces on the house
        var obj3 = obj1["solarPanelConfigs"];
        var nbrOfConfigurations = obj3.length; // Determine all the combinaisons possibles of configuration with the faces on the roof.
        var obj4 = obj3[nbrOfConfigurations - 1]; // we use the last configuration to get the information we calculate the irradiation on each face
        var obj5 = obj4["roofSegmentSummaries"];
        var loss = 0.14;
        const production = []
        for (var key in obj5) {
          var nbrPanel = obj5[key].panelsCount;
          var yearEnergy = obj5[key].yearlyEnergyDcKwh;
          var faceProduction = yearEnergy / nbrPanel / panelCapacity * 1000 * (1 - loss);
          obj2[key].productible = faceProduction;
          production.push(formatValue(faceProduction, 2))
        }
        const roofs = [];
        // const max = production.reduce((maxObj, current, index) => {
        //   const currentProduction = current * roofsegemnts[index].stats.areaMeters2;
        //   if ((maxObj.accumulator * roofsegemnts[maxObj.index].stats.areaMeters2) > currentProduction) {
        //     return maxObj;
        //   }
        //   return { accumulator: current, index: index };
        // }, {
        //   accumulator: 0,
        //   index: 0
        // });
        for (let i = 0; i < roofsegemnts.length; i++) {
          const segment = roofsegemnts[i];
          segment["name"] = 'Pan ' + (i + 1);
          segment["solarproduction"] = production[i];

          // if (i === max.index) {
          //   console.log("Adding to panel " + (i))
          //   // this.addMarker(segment, '' + (i + 1), true);
          // } else {
          //   // this.addMarker(segment, '' + (i + 1), false);
          // }
          roofs.push(segment);
        };
        this.setState({ solarPotential: roofs });
      }
    }
    catch(error){
      this.setState({
        thirdStepCopy:true,
        fourthStepShadow:false,
        step:2
      })
      // console.log(error)
    }
    if(isFastForwad){
      this.setState({
        step:2
      })
    }
  }

  setSolarPotential = (solarPotentials) => {
    this.setState({ solarPotential: solarPotentials });
  };

  sortAndFindOrder(obj) {
    if(this.state.defaultMarker) this.state.defaultMarker.setMap(null)
    const powers = []; // Initial array of powers
  
    // Iterating over each property in the object
    Object.keys(obj).forEach((key) => {
      if (obj.hasOwnProperty(key)) {
        // Calculate the power for each item and push it into the powers array
        let power = obj[key].productible * obj[key].stats.areaMeters2;
  
        // Put negative power for the face oriented in north direction to deprioritize them
        if (obj[key].azimuthDegrees < 90 || obj[key].azimuthDegrees > 270) {
          power = -power;
        }
        powers.push(power);
      }
    });
  
    // Create an array of objects with each power and its original index
    const powersWithIndex = powers.map((value, index) => ({ value, index ,center:obj[index].center,obj:obj[index] }));
    
    // Sort the array of objects by power values, considering special ordering for negative values
    powersWithIndex.sort((a, b) => {
      if (a.value < 0 && b.value < 0) {
        // Both values are negative, sort in ascending order to have descending effect for negative values
        return a.value - b.value;
      } else {
        // Default sort in descending order for positive values
        return b.value - a.value;
      }
    });
  
    // Create an array with the original order of the sorted powers
    // KRUNAL : you can use this table to know the new face's number for pointer
    const sortedFaces = powersWithIndex.map(obj => obj.index);
    // Previously, the number 1 face was the face with index 0, now you can find its index with sortedFaces[0]

    // Update the original object with the face numbers based on new order
    // powersWithIndex.forEach((item, index) => {
    //   if (obj.hasOwnProperty(index)) {
    //     // Update each item in the object with its new face number based on sorting
    //     obj[index].faceNumber = item.index;
    //   }
    // });

    for (const key in sortedFaces) {
      if (sortedFaces.hasOwnProperty(key)) {
        // Add into each face object the right « faceNumber »
        obj[sortedFaces[key]].faceNumber = Number(key) + 1;
      }
    }

    // console.log(sortedFaces, ":sorted")
    // console.log(powersWithIndex, 'sorted array')
    const solarproduction = powersWithIndex[0].value;
    powersWithIndex[0].obj.solarproduction = solarproduction;
    this.setState({
      maxRoof: powersWithIndex[0].obj
    })
    const loc = { lat: powersWithIndex[0].center.latitude, lng: powersWithIndex[0].center.longitude };
    const marker = new window.google.maps.Marker({
      position: loc,
      title: powersWithIndex[0].obj.name,
      label: '',
    });
    // this.setState({maxRoof:segment})
    marker.addListener('click', () => {
      this.setState({ selectedRoof: powersWithIndex[0] });
    });
    marker.setLabel(null)
    marker.setMap(this.state.map)
  };

  updateSimulationTracking = (lastStep) => {
    const baseUrl = process.env.REACT_APP_BACK_BASE_URL
    const path = '/simulation/updateTracking'

    const fullUrl = baseUrl + path    

    fetch(fullUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        idHash: this.state.idHash,
        lastStep: lastStep
      }),
    })
  }

  handleRadioChange = (value) => {
    this.setState({isOwner: value});
  };
  handleCheckboxChange = async(e) => {
    await this.setState({ isChecked: (e.target.checked) });
    this.setState({atleastOneSelected:this.handleDoubleCheck()})
    // console.log(this.state.atleastOneSelected);
    
  }

  handleCheckboxChange1 = async(e) => {
    await this.setState({ isChecked1: e.target.checked });
    this.setState({atleastOneSelected:this.handleDoubleCheck()})
  };
  handleDoubleCheck = () =>{
    if(!this.state.isChecked){
      return false
    }
  };

  handleInputChange = (e, field) => {
    this.setState({ [field]: e.target.value });
  };


  handleAddress1Change = (e) => {
    this.setState({ address1: e.value});
  };

  setSolarPotential1 = (value) => {
    this.setState({ accumulatedValues: value });
  };
  setObstacleSolarPotential1 = (value) => {
    this.setState({ obstacleAccumulatedValues: value });
  };

  setSolarPotential2 = (value) => {
    this.setState({ accumulatedValues1: value });
  };

  setPolygonData = (data) => {
    this.setState({ polygonData: data });
  };

  handlePanelCountChange = (count) => {
    this.setState({ panelCount: count });
  };

  // handleInputChange = (index, value) => {
  //   const newValues = [...this.state.accumulatedValues1];
  //   newValues[index] = parseFloat(value);
  //   this.setState({ accumulatedValues1: newValues });
  // };
  setrecommandedPower = (power) => {
    this.setState({ recommandedPower:power });
  }
  setpanelSurface = (surface) => {
    this.setState({panelsurface:surface});
  }

  componentDidMount = () => {
    const baseUrl = process.env.REACT_APP_BACK_BASE_URL
    const path = '/simulation/createTracking'

    const fullUrl = baseUrl + path

    const searchParams = new URLSearchParams(window.location.search)
    

    fetch(fullUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        companyId: process.env.REACT_APP_COMPANY_ID,

        utmCampaign: searchParams.get('utm_campaign'),
        utmContent: searchParams.get('utm_content'),
        utmMedium: searchParams.get('utm_medium'),
        utmSource: searchParams.get('utm_source'),
        utmTerm: searchParams.get('utm_term')
      }),
    }).then(response => {
      return response.json();
    })
    .then(data => {
      this.setState({
        idHash: data.idHash
      });
    })
    // window.addEventListener('resize', this.handleResize);
    // const script = document.createElement('script');
    // script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyA_Ql5Bfqu0sKMbv5-PtCHSN4klSBactxQ&libraries=places,geometry,drawing`;
    // script.async = true;
    // document.body.appendChild(script);


    //   // Initialize the Places Autocomplete service
    //   const autocompleteService = new window.google.maps.places.AutocompleteService();

      // Example: Fetch autocomplete predictions for "New York"
  
  }

  //  handleInputChange = (event) => {
  //   const inputText = event.target.value;
  //   if (inputText.trim() !== '') {
  //     // Call the Google Places Autocomplete service
  //     const autocompleteService = new window.google.maps.places.AutocompleteService();
  //     autocompleteService.getPlacePredictions({ input: inputText }, (results, status) => {
  //       if (status === 'OK') {
  //         // Update predictions state with the results
  //         this.setPredictions(results);
  //       } else {
  //         console.error('Autocomplete service failed', status);
  //       }
  //     });
  //   } else {
  //     // Clear predictions if the input is empty
  //     setPredictions([]);
  //   }
  // };


  componentWillUnmount = () => {
    window.removeEventListener('resize', this.handleResize);
  }

  handleChildData = (data) => {
    this.setState({placeSelected:data.placeSelected, address: data.address})
    if(data.latLong){
      this.setState({
        latLong:data.latLong
      })
    }
    this.setState({ postalCode: data.postalCode });
  }

  handleResize = () => {
    this.setState({
      windowHeight: window.innerHeight - 80,
    });
  };

//   EconomyCalculation = () => {
//     // Data coming from Google Solar
//     // Krunal : you need to get it from the Designer code
//     const faceProductible = this.state.selectedRoof.solarproduction; // step 2 production
//     const faceSurface = this.state.selectedRoof.stats.areaMeters2.toFixed(2); // step -3 surface while user drawn polygon
//     // all values are coming from when user fill all details 
  
//     ///Required at last step to add in export object
//     // const nameLead = 'Guiot';
//     // const nameLead = this.state.nameLead;
//     // const surnameLead = 'Fred';
//     // const phone = '0618633528';
//     // const email = 'fred.guiot@gmail.com';
// //////////////

//     const monthlyInvoice = this.state.monthlyInvoice; // First step - In French : Quel est le montant mensuel de votre facture d’électricité ?
//     const annualInvoice = 12 * monthlyInvoice;
//     const projectTiming = "Less than 6 month"; // Last step - In french : A quelle échéance souhaitez vous déclencher votre projet solaire  ?
   
//     // Market data
//     const elecPrice = 0.24;
//     const annualkWh = Math.round(annualInvoice / elecPrice);
  
//     // Material data
//     const pannelPower = 425; //selected panel power in step 4
//     const pannelSurface = 1.755 * 1.134; // dimension of pannel // selected panel dimention in step 4
//     const maxNbrPannels = Math.floor(faceSurface / pannelSurface);
//     let maxPowerPerSurface = maxNbrPannels * pannelPower / 1000;
//     maxPowerPerSurface = Math.min(maxPowerPerSurface, 9); // Max power is 9 kWC for residential
//     this.setState({ pannelSurface: pannelSurface });
//     // Configuration values
//     const energyDayPercent = 50;
//     const annualkWhDay = energyDayPercent * annualkWh / 100;
//     const minNbrPannels = 6;
//     const minPower = minNbrPannels * pannelPower / 1000;
  
//     // Calculate the recommanded power to produce the kWh consumed during day
//     let recommandedPower1 = annualkWhDay / faceProductible;
//     let recommandedNbrPannels2 = Math.round(recommandedPower1 / (pannelPower / 1000));
//     let recommandedPower2 = recommandedNbrPannels2 * pannelPower / 1000;
//     let recommandedPower3 = Math.min(maxPowerPerSurface, recommandedPower2);
//     let recommandedPower = Math.max(recommandedPower3, minPower);
//     let recommandedNbrPannels = Math.round(recommandedPower / (pannelPower / 1000));
  
//     // Calculate the economy generated with the power kit
//     const consumptionRate = 85;
//     let consummedkWh = Math.round(consumptionRate * annualkWhDay / 100);
//     let annualProduction = Math.round(faceProductible * recommandedPower);
//     let unusedKwh = annualProduction - consummedkWh;
//     let restkWhBought = annualkWh - consummedkWh;
  
//     const priceIncrease = 6;
//     let noSolarInvoice = (1 + priceIncrease / 100) * elecPrice * annualkWh;
//     let solarInvoice2 = (1 + priceIncrease / 100) * elecPrice * restkWhBought;
  
//     const pricekWhsold = 0.13;
//     let saleKwh = pricekWhsold * unusedKwh;
//     let solarInvoice = solarInvoice2 - saleKwh;
  
//     let savings = Math.round(noSolarInvoice - solarInvoice);
//     let savingsPercent = Math.round(savings / noSolarInvoice * 100); 
  
//     // Calculate Subsidy and pricing
//     let subsidy;
//     const subsidyThreshold1 = 380; // for power <= 3 kWc
//     const subsidyThreshold2 = 290; // for power <= 9 kWc
//     const subsidyThreshold3 = 200; // for power <= 36 kWc
//     if (recommandedPower <= 3) {
//       subsidy = subsidyThreshold1 * recommandedPower;
//     } else if (recommandedPower <= 9) {
//       subsidy = subsidyThreshold2 * recommandedPower;
//     } else {
//       subsidy = subsidyThreshold3 * recommandedPower;
//     }
  
//     // Price list per nbr of pannels
//     const pricingPerNbrPannels = {
//       6: 7500,
//       7: 8000,
//       8: 8500,
//       9: 9000,
//       10: 9800,
//       11: 10500,
//       12: 11250,
//       13: 12000,
//       14: 12800,
//       15: 13750,
//       16: 12850,
//       17: 15750,
//       18: 16850,
//       19: 18000,
//       20: 19250,
//       21: 20500
//     };
  
//     const price = pricingPerNbrPannels[recommandedNbrPannels];
//     const netPrice = Math.round(price - subsidy);
//     // console.log(recommandedPower)
//     this.setState({ recommandedPower: recommandedPower });
//     this.setState({ projectTiming:projectTiming});
    
//     this.setState({ recommandedNbrPannels: recommandedNbrPannels });
//     this.setState({ annualProduction: annualProduction });
//     this.setState({ subsidy: Math.round(subsidy) });
//     this.setState({ savingsPercent: savingsPercent });
//     this.setState({netPrice:netPrice})
//     this.setState({ savings: savings });
//     // this.setState({ address: address });
//     // console.log(address)

//     // console.log(savingsPercent)
//     // console.log(savings)
  
//     // this.setState({ nameLead: nameLead });
//     // console.log(nameLead)
// // Build final object
//       //  this.setState({ nameLead:nameLead});
//       //  this.setState({ surnameLead:surnameLead});
//       //  this.setState({ email:email});
//       //  this.setState({ phone:phone});
//       //  this.setState({ projectTiming:projectTiming});
    


//   }

  render() {
   const {
       mapInstance,isChecked,isChecked1,nameLead, email, phone,projectTiming,
       accumulatedValues, mainpolypath, obstaclepolypath, mainpolypath1, step, accumulatedValues1,
      loading, setSelectedRoof,recommandedNbrPannels,annualProduction, subsidy,savingsPercent,
      netPrice,savings,selectedRoof, selectedRoof1, panelCount,recommandedPower, copypanelCount, savings25Years, amortizationTime, monthlyPayment, recommendedSimulation, minimumSimulation  } = this.state;

      const isFormInvalid =
      !this.state.isChecked|| this.state.emailError ||
      !projectTiming.length > 0 ||
      phone.length !== 10 ||
      !email.length > 0 ||
      !this.state.surnameLead.length > 0 ||
      !nameLead.length > 0;
    // const isHauteurInvalid = accumulatedValues[0] < accumulatedValues[1];
    // const isSurfaceInvalid = accumulatedValues[accumulatedValues.length - 1] < 0;

    // const handleScreenCapture = () => {

    //   console.log(this.state.mapRef)
    //   const mapContainer = this.state.mapRef.current;

    //   const targetElement = document.getElementById('custom-map-id');
    //   //const mapContainer = document.getElementById('custom-map-id');
    //   console.log(mapContainer)
    //   // Check if the map is fully loaded
    //   if (targetElement && targetElement.dataset.mapLoaded === 'true') {
    //     // Introduce a delay to allow the map to render
    //     setTimeout(() => {
    //       console.log(mapview)
    //       html2canvas(mapContainer).then((canvas) => {
    //         const imageDataUrl = canvas.toDataURL('image/png');
    //         console.log(imageDataUrl)
    //         const newWindow = window.open();
    //         newWindow.document.write('<img src="' + imageDataUrl + '" alt="Screen Capture" />');
    //       });
    //     }, 1000); // You can adjust the delay as needed
    //   } else {
    //     console.error('Map not fully loaded. Cannot capture screen.');
    //   }
    // };

    const handleButtonClick = (buttonName,buttonIndex) => {
      this.setState({
        activeButtonRow1:buttonName,
        areaMeters2Manual:this.state.heightOfReactangle[buttonIndex] * this.state.widthOfReactangle[buttonIndex]
      })
    }

    const handleButtonClick2 = (buttonName,buttonIndex) => {
      this.setState({
        activeButtonRow2:buttonName,
        orientationManual:this.state.orientationSelection[buttonIndex],
        inclinaisonManual:this.state.inclinaisonSelection[buttonIndex]
        
      })
    }
    const handleNext = (stepId) => {
      this.updateSimulationTracking(`step-${stepId}`)
      this.setState((prevState) => {
        let nextButtonMarginRight = prevState.nextButtonMarginRight;
        let prevButtonMarginLeft = prevState.prevButtonMarginLeft;

        if (prevState.step === 0) {
          const myLatLng = { lat: this.state.latLong[0], lng: this.state.latLong[1] };
          this.setState({ latlong: myLatLng });
          
          const map = new window.google.maps.Map(document.getElementById("map"), {
            zoom: 20,
            mapTypeId: 'satellite',
            center: myLatLng,
            tilt: 0,
            streetViewControl: false,
            rotateControl: false,
            fullscreenControl: false,
            mapTypeControl: false,
          });

          var yellowPin = {
            url: 'http://maps.google.com/mapfiles/ms/icons/yellow-dot.png', // You can use your own image URL for the yellow pin
            scaledSize: new window.google.maps.Size(48, 48),  // Adjust the size as needed
            origin: new window.google.maps.Point(0, 0),
            anchor: new window.google.maps.Point(24, 48) // Adjust the anchor point if necessary
          }
          const marker = new window.google.maps.Marker({
            position: myLatLng,
            map,
            title: "Hello World!",
            icon: yellowPin 
          });
          
          this.setState({ hideMap: false });
          this.setState({ defaultMarker: marker }); 
          this.setState({ map:map });
        setTimeout(() => {
          drawingManager()
        }, 0);
        
 // Ensure to set the map for drawingManager
        }
        
        


        if (prevState.step === 1) {
      {
            this.loadslardata();
          }
        }

        if (prevState.step === 9) {
            nextButtonMarginRight = 142;
            prevButtonMarginLeft = 142;
          //  if(this.state.hideMap === ''){
          //   return;
          //  }    
           
        }
        
        if (prevState.step === 8) {
          nextButtonMarginRight = 142;
          prevButtonMarginLeft = 142;
         this.state.hideMap= false;
        }

        if (prevState.step === 2 && !this.state.fourthStepShadow && !this.state.maxRoof) {
          let monthlyInvoice = this.state.monthlyInvoice;
          let zipCode = this.state.postalCode;
          let companyId = process.env.REACT_APP_COMPANY_ID;

          let faceProductible = 0
          let faceSurface = this.state.areaMeters2Manual
          let roofSectionType = this.state.activeButtonRow2 == 'flatRoof' ? 'flat_roof' : 'tilted_roof'
          let faceInclinaison = this.state.inclinaisonManual
          let faceOrientation = this.state.orientationManual

          const baseUrl = process.env.REACT_APP_BACK_BASE_URL
          const path = '/calculation/getProductible'

          const fullUrl = baseUrl + path

          fetch(fullUrl, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              monthlyBilling: monthlyInvoice,
              roofSectionSurface: faceSurface,
              roofSectionType: roofSectionType,
              roofSectionShadowCoef: 0,
              roofSectionProductible: faceProductible,
              roofSectionOrientation: faceOrientation,
              roofSectionTilt: faceInclinaison,
              zipCode: zipCode,
              companyId: companyId,
            }),
          })
              .then(response => {
                if (!response.ok) {
                  throw new Error('Network response was not ok');
                }
                return response.json();
              })
              .then(data => {
                this.setState({
                  manualProductible: data
                });
              })
              .catch(error => {
                console.error('Error:', error);
              });
        }


        if (prevState.step === 2 && this.state.fourthStepShadow) {
          this.setState({ secondLoader: true })
          this.setState({ fourthStepShadow: false })
          let monthlyInvoice = this.state.monthlyInvoice;

          let faceSurface
          let faceProductible
          let roofSectionType
          let faceInclinaison
          let faceOrientation
  
          if (this.state.maxRoof != null) {
            faceSurface = this.state.maxRoof.stats.areaMeters2;
            faceProductible = this.state.maxRoof.productible;
            roofSectionType = this.state.maxRoof.type ? this.state.maxRoof.type : 'tilted_roof'
            faceInclinaison = this.state.maxRoof.pitchDegrees;
            faceOrientation = this.state.maxRoof.azimuthDegrees;
          } else {
            faceSurface = this.state.areaMeters2Manual;
            faceProductible = 0;
            roofSectionType = this.state.inclinaisonManual === 0 ? 'flat_roof' : 'tilted_roof'
            faceInclinaison = this.state.inclinaisonManual;
            faceOrientation = this.state.orientationManual;
          }

          let zipCode = this.state.postalCode;
          let companyId = process.env.REACT_APP_COMPANY_ID;

          const baseUrl = process.env.REACT_APP_BACK_BASE_URL
          const path = '/calculation/getSimulation'

          const fullUrl = baseUrl + path

          fetch(fullUrl, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              monthlyBilling: monthlyInvoice,
              roofSectionSurface: faceSurface,
              roofSectionType: roofSectionType,
              roofSectionShadowCoef: 0,
              roofSectionProductible: faceProductible,
              roofSectionOrientation: faceOrientation,
              roofSectionTilt: faceInclinaison,
              zipCode: zipCode,
              companyId: companyId,
            }),
          })
            .then(response => {
              if (!response.ok) {
                throw new Error('Network response was not ok');
              }
              return response.json();
            })
            .then(data => {
              this.setState({
                minimumSimulation: data[0],
                recommendedSimulation: data[1],
                secondLoader: false,
                isFirstLine: false,
                isSecondLine: false,
                isThirdLine: false
              });
            })
            .catch(error => {
              this.setState({ secondLoader: false })
              console.error('Error:', error);
            });

          setTimeout(() => {
            this.setState({ isThirdLine: true })
          }, 1600);
          setTimeout(() => {
            this.setState({ isSecondLine: true })
          }, 800);
          setTimeout(() => {
            this.setState({ isFirstLine: true })
          }, 0);
          this.setState({
            step: 3
          })
        }

        if (prevState.step === 2 && this.state.thirdStepCopy) {
          this.setState({thirdStepCopy:false})
          this.setState({fourthStepShadow:true})

        }

        if(prevState.step === 2 && !this.state.thirdStepCopy){
          this.setState({ hideMap: true })
          this.setState({thirdStepCopy:true})

        }

        if (prevState.step === 3) {
          this.setState({step:4})

        }
        if (prevState.step === 5) {
          if (this.state.selectedRoof1) {
            this.removeMarker1(this.state.selectedRoof1.name);
          } else {
            alert("roof selection is Required")
            return;
          }
        }
        if (prevState.step === 6) {
          const value = Number((this.state.selectedRoof1.azimuthDegrees - 180).toFixed(2));
          mapview.setHeading(value);
          this.removeAllMarkerr1()
        }
         if(prevState.step == 8){
          this.EconomyCalculation()
         }

        var step = prevState.step
        if (prevState.step === 4) {
          step = 8
        } 
        else if(prevState.step === 2){
          if(this.state.selectedRoof){
            if(this.state.selectedRoof.name !== 'manualMode'){
              step = 9;
              this.state.hideMap= false;
              this.EconomyCalculation()
            }else{
              step = 3
            }
          }
        }
        else
        {
          step = prevState.step + 1
        }

        if (step === 8) {

        }
        return { step: step, nextButtonMarginRight, prevButtonMarginLeft };
      });
    };

    const drawingManager = () =>{
      const drawingManager = new window.google.maps.drawing.DrawingManager({
        drawingMode: window.google.maps.drawing.OverlayType.MARKER,
        drawingControl: false,
        polygonOptions: {
          editable: false,
        },
      });

      if(!this.state.drawingMarkerEnabled){ 
        //console.log('drawing manager',drawingManager);
        this.setState({drawingMarkerEnabled:drawingManager})
      }
    
      drawingManager.addListener('markercomplete', (e) => {
        e.setMap(null);
        if(step==2){
        drawingManager.setMap(null);}
    
        // Assuming map is defined elsewhere in your code
        this.state.map.setCenter(e.position);
        this.state.map.setZoom(20);
    
        if (this.state.defaultMarker) {
          this.state.defaultMarker.setMap(null);
          var yellowPin = {
            url: 'http://maps.google.com/mapfiles/ms/icons/yellow-dot.png', // You can use your own image URL for the yellow pin
            scaledSize: new window.google.maps.Size(48, 48),  // Adjust the size as needed
            origin: new window.google.maps.Point(0, 0),
            anchor: new window.google.maps.Point(24, 48) // Adjust the anchor point if necessary
          }
          let mark = new window.google.maps.Marker({
                  position: e.position,
                  map: this.state.map,
                  name: 'locationmarker',
                  title: 'location',
                  icon:yellowPin,
                  draggable: false,
                });
                this.setState({defaultMarker:mark,
                  latlong:{
                    lat:e.position.lat(),
                    lng:e.position.lng()
                  }
                })


         
        }

        

     
    
        // Get the address of the location
        // let geocoder = new window.google.maps.Geocoder();
        // let marker = new window.google.maps.Marker({
        //   position: e.position,
        //   map: this.state.map,
        //   name: 'locationmarker',
        //   title: 'location',
        //   draggable: false,
        // });
    
        // // Add dragend event listener to the marker
        // marker.addListener('dragend', () => {
        //   // Your dragend event handler code here
        // });
      });
    
      drawingManager.setMap(this.state.map);
    }

    const handleModal = () =>{

      this.setState({showModal:true})

    }

    const handleFastForward = () =>{
      this.updateSimulationTracking('step-1bis')
      this.setState({
        thirdStepCopy:true,
        fourthStepShadow:false,
        step:2
      })
      // this.loadslardata(true)
      
    }

    const handleFastForwardSecond =() =>{
      this.updateSimulationTracking('step-1bis')
      this.setState({
        thirdStepCopy:true,
        isManual:true,
        fourthStepShadow:false,
      })
    }

    const handleFormSubmit = async () =>{
      this.setState({ isFormSubmit: true })
      const searchParams = new URLSearchParams(window.location.search)

      const { recommendedSimulation, isChecked1 } = this.state
      const { address_components } = this.state.address
      // console.log(address_components, this.state.address)
      const city = address_components.find(v => v.types.includes('locality')).long_name

      let faceSurface
      let faceInclinaison
      let faceOrientation
  
      if (this.state.maxRoof != null) {
        faceSurface = this.state.maxRoof.stats.areaMeters2;
        faceInclinaison = this.state.maxRoof.pitchDegrees;
        faceOrientation = this.state.maxRoof.azimuthDegrees;
      } else {
        faceSurface = this.state.areaMeters2Manual;
        faceInclinaison = this.state.inclinaisonManual;
        faceOrientation = this.state.orientationManual;
      }

      const pushSimulationDto = {
        idSimulatorTrackingHash: this.state.idHash,
        client: {
          lastName: this.state.nameLead,
          firstName: this.state.surnameLead,
          phone: this.state.phone,
          zipCode: this.state.postalCode,
          email: this.state.email,
          address: this.state.address.formatted_address.split(',')[0],
          city: city
        },

        house: {
          ownerStatus: this.state.isOwner, 
          annualBilling: this.state.monthlyInvoice * 12,
          roof: {
            surface: faceSurface,
            orientation: faceOrientation,
            tilt: faceInclinaison
          }        
        },

        simulation: {
          recommandedPower: recommendedSimulation.recommandedPower,
          panelNumber: recommendedSimulation.recommandedNbrPannels,
          kitId: recommendedSimulation.kitId,
          savingsPercent: recommendedSimulation.savingsPercent,
          savings: recommendedSimulation.savingsFirstYear,
          annualProduction: recommendedSimulation.annualProduction,
          financialAid: recommendedSimulation.subsidy,
          price: recommendedSimulation.price,
          taxesRate: recommendedSimulation.taxesRate
        },
      
        marketingAgreement: this.state.isChecked1 ? "D'accord" : '',
      
        projectTiming: this.state.projectTiming,
        leadDemand: 'rappel',
      
        utmCampaign: searchParams.get('utm_campaign'),
        utmContent: searchParams.get('utm_content'),
        utmMedium: searchParams.get('utm_medium'),
        utmSource: searchParams.get('utm_source'),
        utmTerm: searchParams.get('utm_term')
      }
      const baseUrl = process.env.REACT_APP_BACK_BASE_URL
      const path = '/simulation/push'

      const fullUrl = baseUrl + path

      await fetch(fullUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(pushSimulationDto) 
      }).finally(v => this.setState({ isFormSubmit: false }))

      window.top.location.href = 'https://bd-renovation-energetique.fr/solaire/photovoltaique/remerciements/'
    }

    const handleDoubleCheck = () =>{
      if(this.state.isChecked || this.state.isChecke1){
        return true
      }
      return false
    }

    const handleNextKO = () =>{
      this.updateSimulationTracking('step-2')
      this.setState({
        thirdStepCopy:false,
        fourthStepShadow:true,
        step:2
      })
    }



    const handleExport = () => {
      // const { isOwner } = this.state;
      const isOwnerValue = this.state.isOwner;
      
     
      if(this.state.selectedRoof !== 'manualMode'){
        const objadd = {
          // Address1: this.state.address || 'Address not available',
          Address:  this.state.address1,
          // OwnerHouse:this.state.isOwner,
          OwnerHouse: this.state.isOwner, // Accessing isOwner from state
          FaceNumber: this.state.selectedRoof.name,   //pan selection 
          FaceProductible:  this.state.selectedRoof.solarproduction,  //Productible annuel (kWh / kWc / an)
          FaceSurface:  this.state.selectedRoof.stats.areaMeters2.toFixed(2),    //Surface brute (m2)
          Orientation:  this.state.selectedRoof.azimuthDegrees-180, //Orientation (en °)
          Inclinaison:  this.state.selectedRoof.pitchDegrees  //Inclinaison (en °)
        };
        // console.log('Owner House:', this.state.isOwner);
        const updatedIsOwnerValue = 'SomeValue';
        this.setState({
          isOwner: updatedIsOwnerValue
        });
        
      
        const LeadInfo= {
          // AnnualInvoice: annualInvoice,
          Name: nameLead,
          Surname: this.state.surnameLead,
          Telephone: phone,
          Email: email,
        }

        const projectInfo= {
          ProjectTiming: projectTiming,
          RecommandedPower: recommandedPower,
          NbrPannels: recommandedNbrPannels,
         // PannelPower: pannelPower,
          AnnualProd: annualProduction,
          Subsidy: subsidy,
          EconomyPercentage: savingsPercent,
          EconomyValue: savings,
          //FirstPrice: price,
          NetPrice: netPrice
        };
        //console.log(objadd)
        //console.log(hoseinfo)

      }
      else{
        const obj1 = {
          height: (accumulatedValues[0] > accumulatedValues[1]) ? accumulatedValues[1] : accumulatedValues[0],// height of the drawn rectangle
          length: (accumulatedValues[0] > accumulatedValues[1]) ? accumulatedValues[0] : accumulatedValues[1], // width of the drawn rectangle
          type: selectedRoof.pitchDegrees > 1 ? 'Pitched' : 'Flat', // if roof face is "Pitched" or "Flat"
          orientation: selectedRoof.azimuthDegrees - 180, // orientation of the face (coming from Google solar or edge segment selection in manual mode)
          tilt: selectedRoof.pitchDegrees, // Inclination of the face (coming from Google solar or the one set by hand in manual mode)
          shadow: 0, // put 0
          shadowCoef: 0,// put 0
          panelNumber: panelCount, // panels number of the face
          productible: selectedRoof.solarproduction // 
        }
        if(selectedRoof1){
          const obj2 = {
            height: (accumulatedValues1[0] > accumulatedValues1[1]) ? accumulatedValues1[1] : accumulatedValues1[0],// height of the drawn rectangle
            length: (accumulatedValues1[0] > accumulatedValues1[1]) ? accumulatedValues1[0] : accumulatedValues1[1], // width of the drawn rectangle
            type: selectedRoof1.pitchDegrees > 1 ? 'Pitched' : 'Flat', // if roof face is "Pitched" or "Flat"
            orientation: selectedRoof1.azimuthDegrees - 180, // orientation of the face (coming from Google solar or edge segment selection in manual mode)
            tilt: selectedRoof1.pitchDegrees, // Inclination of the face (coming from Google solar or the one set by hand in manual mode)
            shadow: 0, // put 0
            shadowCoef: 0,// put 0
            panelNumber: copypanelCount, // panels number of the face
            productible: selectedRoof1.solarproduction // 
          }
          
        }
      }
     
    }
   

    const handlePrevious = () => {
      this.setState((prevState) => {
        let nextButtonMarginRight = prevState.nextButtonMarginRight;
        let prevButtonMarginLeft = prevState.prevButtonMarginLeft;
        if (prevState.step > 0) {
          nextButtonMarginRight = 142;
          prevButtonMarginLeft = 142;  
        }
        if (prevState.step > 9) {
          nextButtonMarginRight = 142;
          prevButtonMarginLeft = 142;
          
        }

        if (prevState.step === 8 && copypanelCount === 0) {
          return { step: 4, nextButtonMarginRight, prevButtonMarginLeft };

        } else if (prevState.step === 9) { // Check if step is 9
          const  hideMap = true; // Set hideMap to true
          if(this.state.selectedRoof){
            if(this.state.selectedRoof.name !== 'manualMode'){
              return { step: 2, nextButtonMarginRight, prevButtonMarginLeft,hideMap :hideMap };
            }else{
               return { step: prevState.step - 1, nextButtonMarginRight, prevButtonMarginLeft,hideMap :hideMap };}
          }
          
        } else {
          return { step: prevState.step - 1, nextButtonMarginRight, prevButtonMarginLeft };
        }
      });
    };

    const addstep = () => {
      this.viewAllMarkerr1()
      this.setState((prevState) => {
        let nextButtonMarginRight = prevState.nextButtonMarginRight;
        let prevButtonMarginLeft = prevState.prevButtonMarginLeft;
        if (prevState.step > 1) {
          nextButtonMarginRight = 142;
          prevButtonMarginLeft = 142;
        }
        return { step: 5, nextButtonMarginRight, prevButtonMarginLeft };
      });
    };

    const handleSelectedRoofChange = (newSelectedRoof) => {
      setSelectedRoof(newSelectedRoof);
    };

    const handlePitchChange = () => {
    };

    const handleOrientationChange = () => {
    };

    const handlePanelCountChange = (count,power,surface) => {
      this.setState({ panelCount: count,panelPower:power,panelsurface:surface });
    };

    const CopyhandlePanelCountChange = (count) => {
      this.setState({ copypanelCount: count });
    };




    const handleedgesideOrientationChange1 = (event) => {
      this.setState((prevState) => ({
        selectedRoof1: {
          ...prevState.selectedRoof1,
          azimuthDegrees: event.target.value,
        },
      }));
    }

    const handleedgesideInclinaisonChange1 = (event) => {
      this.setState((prevState) => ({
        selectedRoof1: {
          ...prevState.selectedRoof1,
          pitchDegrees: event.target.value,
        },
      }));
    };


    return (
      <div className='container'>
         <div className='main-wrapper-component border-3 border border-danger'>

        {this.state.loading &&
          <div className=' d-flex justify-content-center align-items-center flex-column h-100 my-auto w-100 mx-auto p-5'>
            <div><img src={require("../assets/image/loadingCrop.gif")} />
            </div>
            <div className='text-wrapper '>
              <ul class="arrow-list d-flex align-items-start justify-content-center flex-column">
                {
                  this.state.isFirstLine && (<>
                                 <div className='d-flex align-items-center justify-content-between'><span className='mx-2 arrow-list-arrow span-diff'>1</span>
                    <p className='arrow-list-arrow my-1 mx-2'> &#x2192;</p>
                    <li>{this.state.listOfLoadingPoints[0][0]}</li></div>
                  </>)
                }
                                {
                  this.state.isSecondLine && (<>
                                 <div className='d-flex align-items-center justify-content-between'><span className='mx-2 arrow-list-arrow span-diff'>2</span>
                    <p className='arrow-list-arrow my-1 mx-2'> &#x2192;</p>
                    <li>{this.state.listOfLoadingPoints[0][1]}</li></div>
                  </>)
                }
                                {
                  this.state.isThirdLine && (<>
                                 <div className='d-flex align-items-center justify-content-between'><span className='mx-2 arrow-list-arrow span-diff'>3</span>
                    <p className='arrow-list-arrow my-1 mx-2'> &#x2192;</p>
                    <li>{this.state.listOfLoadingPoints[0][2]}</li></div>
                  </>)
                }
              </ul>
            </div>
          </div>
        }
        {this.state.secondLoader &&
          <div className='d-flex justify-content-center align-items-center flex-column p-5'>
            <div><img src={require("../assets/image/loadingCrop.gif")} /></div>
            <div className='text-wrapper '>
              <ul class="arrow-list d-flex align-items-start justify-content-center flex-column">
              {
                  this.state.isFirstLine && (<>
                                 <div className='d-flex align-items-center justify-content-between'><span className='mx-2 arrow-list-arrow span-diff'>1</span>
                    <p className='arrow-list-arrow my-1 mx-2'> &#x2192;</p>
                    <li>{this.state.listOfLoadingPoints[1][0]}</li></div>
                  </>)
                }
                                {
                  this.state.isSecondLine && (<>
                                 <div className='d-flex align-items-center justify-content-between'><span className='mx-2 arrow-list-arrow span-diff'>2</span>
                    <p className='arrow-list-arrow my-1 mx-2'> &#x2192;</p>
                    <li>{this.state.listOfLoadingPoints[1][1]}</li></div>
                  </>)
                }
                                {
                  this.state.isThirdLine && (<>
                                 <div className='d-flex align-items-center justify-content-between'><span className='mx-2 arrow-list-arrow span-diff'>3</span>
                    <p className='arrow-list-arrow my-1 mx-2'> &#x2192;</p>
                    <li>{this.state.listOfLoadingPoints[1][2]}</li></div>
                  </>)
                }
              </ul>
            </div>
          </div>
        }
        <div style={{  }} className='mx-auto ' hidden={this.state.loading || this.state.secondLoader}>
          <div className='stepper-class' hidden={this.state.step >= 4}>
            <ThemeProvider theme={theme} >
              <Stepper activeStep={step} className='my-3 mx-auto px-4' style={{ minHeight: '32px' }} >
                <Step key={1} className='step-main'>
                  <StepLabel />
                </Step>
                <Step key={2} className='step-main'>
                  <StepLabel />
                </Step>
                <Step key={3} className='step-main'>
                  <StepLabel />
                </Step>
                <Step key={4} className='step-main'>
                  <StepLabel />
                </Step>
              </Stepper>
            </ThemeProvider>
          </div>
          <div className='text-center mx-auto mt-2' >
            <label className='big-title' style={{ display: `${(this.state.thirdStepCopy || this.state.fourthStepShadow ? 'none' : 'block')}` }}>{this.state.labelArray[step]}</label>
          </div>
          <div className={`bd-highlight ${this.state.thirdStepCopy || this.state.fourthStepShadow ? '' : 'd-flex'}`} style={{ height: `${(this.state.step > 0 ? '70vh' : 'auto')}`, display: `${(this.state.thirdStepCopy || this.state.fourthStepShadow ? 'none' : 'flex')}` }}>

            <div id="map" className='map-secstep' style={{ }} hidden={this.state.hideMap}></div>



            <div class="architectural-proximity-container w-100 p-2" style={{}} hidden={!this.state.hideMap || step === 2}>


              {step === 0 && (

                <div>



                  <label className="label-class">De quel type de logement je suis propriétaire ?</label>
                  <div style={{ marginBottom: '20px' }}>
                    <Row style={{ display: 'flex', justifyContent: 'space-between', color: 'black', flexWrap: 'wrap' }}>
                <Col md={6} sm={12}>
                      <Button
                        type='radio'
                        name="btnradio"
                        id="btnradio1"
                        autoComplete="off"
                        active={this.state.isOwner === 'Maison'}
                        value={'Maison'}
                        variant=""
                        
                        className={` w-100 ${(this.state.isOwner === 'Maison' ? 'danger-radio-red' : 'danger-radio-dark')}`}
                        onClick={() => this.handleRadioChange('Maison')}
                      >
                        <div className='radio-timg-sec'>
                          <div className={`radiotext ${(this.state.isOwner === 'Maison' ? 'text-danger' : 'text-dark')} m-0`}>Maison</div>
                          <div>
                          <img src={houseSvg} className='img-fluid' />
                          </div>
                        </div>
                      </Button>
                      </Col>
                <Col md={6} sm={12}>
                      <Button type='radio' value={'Appartement'}                       
                      className={`w-100 ${(this.state.isOwner === 'Appartement' ? 'danger-radio-red' : 'danger-radio-dark')}`} 
                       active={this.state.isOwner === 'Appartement'} 
                      variant=""
                      onClick={() => this.handleRadioChange('Appartement')}
                      >
                        <div className='radio-timg-sec'>
                        <div className={`radiotext ${(this.state.isOwner === 'Appartement' ? 'text-danger' : 'text-dark')} m-0`}>Appartement</div>
                        <div>
                          <img src={appartmentSvg} className='img-fluid' />
                          </div>
                        </div>
                      </Button>
                      </Col>
                    </Row>
                    {
                      this.state.isOwner === 'Appartement' ? <div className='d-flex align-items-center justify-content-end'><p style={{ float: "right" }} className='text-danger m-0'><span className='text-danger m-0' style={{ fontWeight: "bold", fontSize: "25px" }}>&#8594;</span> Offre uniquement pour une maison</p></div> : <></>
                    }
                  </div>
                  <div className="">
                    <label htmlFor="autoCompleteInput" className='label-class'>Quelle est votre adresse ?</label>
                    <AutoCompleteMain parentHandleInput={(e) => this.handleChildData(e)} />
                  </div>

                  <div class="mt-4">
                    <label for="customRange1" class="label-class ">
                      Quel est le montant mensuel de votre facture d’électricité ?
                    </label>
                    <ThemeProvider theme={sliderTheme}>
                    <Slider class="form-range"   min={40} max={500} defaultValue={250}
                      step={10} marks={this.state.marks} aria-label="Always visible" onChange={this.setMonthlyInvoice} valueLabelDisplay="auto"
                      valueLabelFormat={(value) => `${value} €`} />
                      </ThemeProvider>
                    {
                      this.state.valueLow ? <div className='d-flex align-items-center justify-content-end'><p style={{ float: "right" }} className='text-danger m-0'><span className='text-danger m-0' style={{ fontWeight: "bold", fontSize: "25px" }}>&#8594;</span> Facture trop basse pour passer au solaire</p></div> : <></>
                    }
                  </div>

                  <Button
                    // variant="warning"
                    variant={(!this.state.valueLow && this.state.placeSelected) && this.state.isOwner !== 'Appartement' ? "flat" : "secondary"}
                    disabled={this.state.valueLow || !this.state.placeSelected || this.state.isOwner === 'Appartement'}
                    onClick={() => handleNext(0)}
                    className={` text-light w-100 ${(this.state.valueLow ? 'my-3' : '')}`}
                  >
                    J'identifie ma maison
                  </Button>

                </div>
              )}



              {/* {step === 2 && (
              <Form.Group controlId="formStep2">
                <div>
                  <p style={{
                    borderRadius: '5px',
                    color: '#008000',
                    margin: '0',
                    marginBottom: '10px',
                    padding: '10px',
                    border: '2px solid green'
                  }}>Etape 3 sur 5 : Potentiel solaire de votre maison</p>
                  <div>
                    <div className="form-group">
                      <label htmlFor="roofSelection">
                        Pan sélectionné:
                        {loading && (
                          <Spinner animation="border" role="status" style={{ width: '15px', height: '15px', marginLeft: '11px' }}>
                            <span className="visually-hidden">Loading...</span>
                          </Spinner>
                        )}
                      </label>

                      <select
                        className="form-control"
                        id="roofSelectionStep2"
                        value={this.state.selectedRoof ? this.state.selectedRoof.name : ''}
                        onChange={(e) => {
                          const selectedValue = e.target.value;
                          if (selectedValue !== '') {
                            const selectedRoof = (selectedValue === 'manualMode') ?
                              { name: 'manualMode', azimuthDegrees: 0, stats: { areaMeters2: 0 }, pitchDegrees: 0, solarproduction: 0 }
                              : this.state.solarPotential.find(option => option.name === selectedValue);
                            this.setState({ selectedRoof });
                          }
                        }}
                      >
                        <option value=''>--Sélectionnez un pan--</option>
                        <option value="manualMode">Mode manuel</option>
                        {this.state.solarPotential && (
                          <>

                            <option value=''>--Sélectionnez un pan--</option>
                            {this.state.solarPotential.map((option) => (
                              <option key={option.name} value={option.name}>
                                {option.name}
                              </option>
                            ))}
                          </>
                        )}
                      </select>
                    </div>

                    <br />

                    {this.state.selectedRoof && (
                      <>

                        <div className="form-group mb-2">
                          <label htmlFor="shadingPercentageDropdown" className="form-label">Type de toit</label>
                          <select disabled
                            className="form-control"
                            id="shadingPercentageDropdown"
                          >
                            {selectedRoof && selectedRoof.pitchDegrees > 1 ? (
                              <>
                                <option value="pitch">Incliné</option>
                              </>
                            ) : (
                              <option value="flat">Flat</option>
                            )}
                          </select>
                        </div>

                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                          <div className="form-group">
                            <label htmlFor="sunshinePerYear" style={{ marginBottom: '5px' }}>Productible annuel</label>
                            <input
                              type="text"
                              className="form-control"
                              id="sunshinePerYear"
                              value={this.state.selectedRoof ? Math.round(this.state.selectedRoof.solarproduction) + ' kWh / kWc / an' : ''}

                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="surface">Surface exploitable</label>
                            <input
                              type="text"
                              className="form-control"
                              id="surface"
                              value={this.state.selectedRoof ? Math.round(this.state.selectedRoof.stats.areaMeters2).toFixed(0) + ' m2' : ''}

                            />
                          </div>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                          <div className="form-group" style={{ marginRight: '10px' }}>
                            <label htmlFor="orientation">Orientation</label>
                            <input
                              type="text"
                              className="form-control"
                              id="orientation"

                              value={this.state.selectedRoof ? (Math.round(this.state.selectedRoof.azimuthDegrees) === 0 ? 0 : (this.state.selectedRoof.azimuthDegrees - 180).toFixed(0)) + ' °' : ''}
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="pitch">Inclinaison</label>
                            <input
                              type="text"
                              className="form-control"
                              id="pitch"
                              // value={this.state.selectedRoof.pitchDegrees.toFixed(2)+ ' °'}
                              value={this.state.selectedRoof ? Math.round(this.state.selectedRoof.pitchDegrees).toFixed(0) + ' °' : ''}
                            />
                          </div>
                        </div>

                      </>
                    )}
                  </div>
                </div>
              </Form.Group>
            )} */}


              {/* {step === 3 && (
              <div>
                <p style={{
                  backgroundColor: '#d9d9d9',
                  borderRadius: '5px',
                  color: '#008000',
                  margin: '0',
                  marginBottom: '10px',
                  padding: '10px',
                  border: '2px solid green' // Add this line for green border
                }}>Etape 3 : Potentiel solaire</p>
                <div>
                  <Form.Group controlId="formStep3">
                    <DrawingComponent
                      map={mapInstance}
                      setSolarPotential1={this.setSolarPotential1}
                      setObstacleSolarPotential1={this.setObstacleSolarPotential1}
                      getmainPolygon={this.getmainPolygon}
                      getobstaclePolygon={this.getobstaclePolygon}
                      paneldraw={1}
                      ref={this.childRef}
                      onRemoveBorder={(ref) => (this.drawingComponentRef = ref)} 
                    />

                    <div>
                    

                      <div>
                        <label>Pan sélectionné</label>
                        <select
                          disabled
                          className="form-control"
                          id="roofSelectionFace1" 
                          value={this.state.selectedRoof ? this.state.selectedRoof.name : ''}
                        >
                   
                          {this.state.selectedRoof && (
                            <option value={this.state.selectedRoof.name}>
                              {this.state.selectedRoof.name}
                            </option>
                          )}

                          {!this.state.selectedRoof || !this.state.selectedRoof.name ? (
                            <option value="manualMode">Manual Mode</option>
                          ) : null}
                        </select>
                      </div>
                      <div className="form-group mb-2">
                        <label htmlFor="shadingPercentageDropdown" className="form-label">Type de toit</label>
                        <select disabled
                          className="form-control"
                          id="shadingPercentageDropdown"
                        >
                          {selectedRoof && selectedRoof.pitchDegrees > 1 ? (
                            <>
                              <option value="pitch">Incliné</option>
                            </>
                          ) : (
                            <option value="flat">Flat</option>
                          )}
                        </select>
                      </div>

                      <div>
                        <label>Dimension de la section</label>
                        <div className="form-group">
                          <label htmlFor="orientation">Largeur:</label>
                          <input
                            type="number"
                            className="form-control"
                            value={formatValue((accumulatedValues[0] > accumulatedValues[1]) ? accumulatedValues[0] : accumulatedValues[1], 2)}
                        
                          />

                        </div>
                      </div>

                      <div>
                        <div className="form-group">
                          <label htmlFor="orientation">Hauteur:</label>
                          <input
                            type="number"
                            className="form-control"
                            value={formatValue((accumulatedValues[0] > accumulatedValues[1]) ? accumulatedValues[1] : accumulatedValues[0], 2)}
                          />

                        </div>
                      </div>

                      <div>
                        <div className="form-group">
                          <label htmlFor="orientation">Surface:</label>
                          <input
                            type="number"
                            className="form-control"
                            value={formatValue(accumulatedValues[accumulatedValues.length - 1], 2)}
                          />

                        </div>
                      </div>

                      <div className="form-group">
                        <label htmlFor="orientation">Orientation</label>
                        <div className="input-group">
                          <input
                            type="number"
                            className="form-control" id="orientation"
                            onChange={handleedgesideOrientationChange}
                            
                            value={this.state.selectedRoof ? (Math.round(this.state.selectedRoof.azimuthDegrees) - 180).toFixed(0) + ' °' : ''}

                          />

                        </div>
                      </div>

                      <div className="form-group">
                        <label htmlFor="pitch">Inclinaison</label>
                        <input
                          type="number"
                          className="form-control"
                          id="pitch"
                          onChange={handleedgesideInclinaisonChange}
                  
                          value={this.state.selectedRoof ? Math.round(this.state.selectedRoof.pitchDegrees) + ' °' : ''}
                        />
                      </div>

                    </div>
                  </Form.Group>
                </div>
              </div>
            )} */}

              {/* {step === 4 && (
              <div>
                <p style={{
                  backgroundColor: '#d9d9d9',
                  borderRadius: '5px',
                  color: '#008000',
                  margin: '0',
                  marginBottom: '10px',
                  padding: '10px',
                  border: '2px solid green' 
                }}>Etape 4 : Pré calepinage des panneaux</p>
                <div >
    
                  <Form.Group controlId="formStep4">
                    <div>
                      <DrawingRectComponent
                        map={mapInstance}
                        mainpolypath={mainpolypath}
                        obstaclepolypath={obstaclepolypath}
                        selectedRoof={selectedRoof}
                        onSelectedRoofChange={handleSelectedRoofChange}
                        onOrientationChange={handleOrientationChange}
                        accumulatedValues={accumulatedValues}
                        onPitchChange={handlePitchChange}
                        onPanelCountChange={handlePanelCountChange}
                      />
                    </div>
                  </Form.Group>
                </div>
              </div>
            )} */}


              {step === 5 && (
                <Form.Group controlId="formStep2">
                  <div>
                    <p style={{
                      borderRadius: '5px',
                      color: '#008000',
                      margin: '0',
                      marginBottom: '10px',
                      padding: '10px',
                      border: '2px solid green'
                    }}>Etape 2 : Potential solaire new</p>
                    <div>
                      <div className="form-group">
                        <label htmlFor="roofSelection">
                          Pan sélectionné:
                          {loading && (
                            <Spinner animation="border" role="status" style={{ width: '15px', height: '15px', marginLeft: '11px' }}>
                              <span className="visually-hidden">Loading...</span>
                            </Spinner>
                          )}
                        </label>
                        <select
                          className="form-control"
                          id="roofSelectionStep2"
                          value={this.state.selectedRoof1 ? this.state.selectedRoof1.name : ''}
                          onChange={(e) => {
                            const selectedValue = e.target.value;
                            if (selectedValue !== '') {
                              const selectedRoof1 = (selectedValue === 'manualMode') ?
                                { name: 'manualMode', azimuthDegrees: 0, stats: { areaMeters2: 0 }, pitchDegrees: 0, solarproduction: 0 }
                                : this.state.solarPotential.find(option => option.name === selectedValue);
                              this.setState({ selectedRoof1 });
                            }
                          }}
                        >
                          <option value=''>--Sélectionnez un pan--</option>
                          <option value="manualMode">Mode manuel</option>
                          {this.state.solarPotential && (
                            <>

                              {/* <option value=''>--Sélectionnez un pan--</option> */}
                              {this.state.solarPotential.map((option) => (
                                <option key={option.name} value={option.name}>
                                  {option.name}
                                </option>
                              ))}
                            </>
                          )}
                        </select>
                      </div>

                      <br />

                      {this.state.selectedRoof1 && (
                        <>
                          <div className="form-group mb-2">
                            <label htmlFor="shadingPercentageDropdown" className="form-label">Type de toit</label>
                            <select disabled
                              className="form-control"
                              id="shadingPercentageDropdown"
                            >
                              {selectedRoof && selectedRoof.pitchDegrees > 1 ? (
                                <>
                                  <option value="pitch">Incliné</option>
                                </>
                              ) : (
                                <option value="flat">Flat</option>
                              )}
                            </select>
                          </div>
                          <div className="form-group">
                            <label htmlFor="sunshinePerYear">Productible annuel (kWh / kWc / an):</label>
                            <input
                              type="text"
                              className="form-control"
                              id="sunshinePerYear"
                              value={this.state.selectedRoof1 ? this.state.selectedRoof1.solarproduction : ''}
                            /// Set your default or empty value here
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="surface">Surface brute (m2):</label>
                            <input
                              type="number"
                              className="form-control"
                              id="surface"
                              value={0}
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="orientation">Orientation</label>
                            <input
                              type="number"
                              className="form-control" id="orientation"
                              value={(this.state.selectedRoof1.azimuthDegrees < 1 && this.state.selectedRoof1.azimuthDegrees >= 0)
                                ? 0 : (this.state.selectedRoof1.azimuthDegrees - 180).toFixed(2)}
                            />
                          </div>

                          <div className="form-group">
                            <label htmlFor="pitch">Inclinaison</label>
                            <input
                              type="number"
                              className="form-control"
                              id="pitch"
                              value={this.state.selectedRoof1.pitchDegrees.toFixed(2)}
                            />
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </Form.Group>
              )}

              {step === 6 && (
                <div>
                  <p style={{
                    backgroundColor: '#d9d9d9',
                    borderRadius: '5px',
                    color: '#008000',
                    margin: '0',
                    marginBottom: '10px',
                    padding: '10px',
                    border: '2px solid green' // Add this line for green border
                  }}>Etape 3 : Potentiel solaire</p>
                  <div >

                    {/* Form for Step 3 */}
                    <Form.Group controlId="formStep3">
                      <CopyDrawingComponent
                        map={mapInstance}
                        setSolarPotential2={this.setSolarPotential2}
                        getmainPolygon1={this.getmainPolygon1}
                        paneldraw={1}
                      />

                      <div>
                        {/* Roof selection face 1 dropdown and inputs */}
                        <div>
                          <label>Pan sélectionné</label>
                          <select
                            disabled
                            className="form-control"
                            id="roofSelectionFace1"  // Unique id for Step 3 dropdown
                            value={this.state.selectedRoof1 ? this.state.selectedRoof1.name : ''}
                          >
                            {/* Single option with the selected roof's name */}
                            {this.state.selectedRoof1 && (
                              <option value={this.state.selectedRoof1.name}>
                                {this.state.selectedRoof1.name}
                              </option>
                            )}
                          </select>
                        </div>
                        <div className="form-group mb-2">
                          <label htmlFor="shadingPercentageDropdown" className="form-label">Type de toit</label>
                          <select disabled
                            className="form-control"
                            id="shadingPercentageDropdown"
                          >
                            {selectedRoof && selectedRoof.pitchDegrees > 1 ? (
                              <>
                                <option value="pitch">Incliné</option>
                              </>
                            ) : (
                              <option value="flat">Flat</option>
                            )}
                          </select>
                        </div>
                        <div>
                          <label>Dimension de la section</label>
                          <div className="form-group">
                            <label htmlFor="orientation">Largeur:</label>
                            <input
                              type="number"
                              className="form-control"
                              value={formatValue((accumulatedValues1[0] > accumulatedValues1[1]) ? accumulatedValues1[0] : accumulatedValues1[1], 2)}
                            />
                          </div>
                        </div>

                        <div>
                          <div className="form-group">
                            <label htmlFor="orientation">Hauteur:</label>
                            <input
                              type="number"
                              className="form-control"
                              value={formatValue((accumulatedValues1[0] > accumulatedValues1[1]) ? accumulatedValues1[1] : accumulatedValues1[0], 2)}
                            />
                          </div>
                        </div>

                        <div>
                          <div className="form-group">
                            <label htmlFor="orientation">Surface:</label>
                            <input
                              type="number"
                              className="form-control"
                              value={formatValue(accumulatedValues1[accumulatedValues1.length - 1], 2)}
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <label htmlFor="orientation">Orientation</label>
                          <input
                            type="number"
                            className="form-control" id="orientation"
                            onChange={handleedgesideOrientationChange1}
                            value={(this.state.selectedRoof1.azimuthDegrees - 180).toFixed(2)}
                          />
                        </div>

                        <div className="form-group">
                          <label htmlFor="pitch">Inclinaison</label>
                          <input
                            type="number"
                            className="form-control"
                            id="pitch"
                            onChange={handleedgesideInclinaisonChange1}
                            value={this.state.selectedRoof1.pitchDegrees}
                          />
                        </div>
                      </div>
                    </Form.Group>
                  </div>
                </div>
              )}

              {step === 7 && (
                <div>
                  <p style={{
                    backgroundColor: '#d9d9d9',
                    borderRadius: '5px',
                    color: '#008000',
                    margin: '0',
                    marginBottom: '10px',
                    padding: '10px',
                    border: '2px solid green' // Add this line for green border
                  }}>Etape 4 : Pré calepinage des panneaux</p>
                  <div >
                    {/* Form for Step 4 */}
                    <Form.Group controlId="formStep4">
                      <div>
                        <CopyDrawingRectComponent
                          map={mapInstance}
                          mainpolypath1={mainpolypath1}

                          selectedRoof={selectedRoof1}
                          onSelectedRoofChange={handleSelectedRoofChange}
                          onOrientationChange={handleOrientationChange}
                          onPitchChange={handlePitchChange}
                          accumulatedValues1={accumulatedValues1}
                          copyonPanelCountChange={CopyhandlePanelCountChange}
                        />
                      </div>
                    </Form.Group>
                  </div>
                </div>
              )}

              {/* add new part End */}

              {step === 8 && (
                <div>
                  <p>Etape 5/5: Vue de la maison avec panneaux</p>
                  <p style={{
                    backgroundColor: '#d9d9d9',
                    borderRadius: '5px',
                    color: '#008000',
                    margin: '0',
                    marginBottom: '10px',
                    padding: '10px',
                    border: '2px solid green' // Add this line for green border
                  }}>Vue de la maison avec panneaux</p>
                  <div >

                    {/* Form for Step 3 */}
                    <Form.Group controlId="formStep8">


                      <div>

                        {/* Roof selection face 1 dropdown and inputs */}
                        <div className="row">
                          <div className="col-sm mb-2">
                            <label className="form-label">Pan sélectionné:</label><br />
                            <span>Pan 1</span>
                            {/* <span>{this.state.selectedRoof.name}</span> */}
                          </div>
                          <div className="col-sm mb-2">
                            <label className="form-label">Nbr de panneauxs:</label><br />
                            <span>{panelCount}</span>
                          </div>
                        </div>

                        {copypanelCount > 0 && (
                          <div className="row">
                            <div className="col-sm mb-2">
                              <label className="form-label">Pan sélectionné:</label><br />
                              <span>Pan 2</span>
                            </div>
                            <div className="col-sm mb-2">
                              <label className="form-label">Nbr de panneaux:</label><br />
                              <span>{copypanelCount}</span>
                            </div>
                          </div>
                        )}
                      </div>
                    </Form.Group>
                  </div>
                </div>
              )}
              {step === 9 && (
                <div className="">

                  <div>
                    <p style={{
                      borderRadius: '5px',
                      color: '#008000',
                      margin: '0',
                      marginBottom: '10px',
                      padding: '10px',
                      border: '2px solid green' // Add this line for green border
                    }}>Etape 4 sur 5 : Notre recommandation pour un rendement optimal</p>
                  </div>

                  <div style={{ marginTop: '30px' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <div className="form-group">
                        <label htmlFor="power">Puissance</label>
                        <input
                          type="text"
                          disabled="true"
                          className="form-control"
                          id="power"
                          value={recommandedPower + ' kWc'}
                        />
                      </div>

                      <div className="form-group">
                        <label htmlFor="panelCount">Nombre de panneaux</label>
                        <input type="number"
                          disabled="true"
                          className="form-control"
                          id="panelCount"
                          value={recommandedNbrPannels}
                        />
                      </div>
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <div className="form-group">
                        <label htmlFor="annualProduction">Production annuelle </label>
                        <input type="text"
                          disabled="true"
                          className="form-control"
                          id="annualProduction"
                          value={annualProduction + ' kWh/an'}
                        />
                      </div>

                      <div className="form-group">
                        <label htmlFor="stateAid">Aide de l’état</label>
                        <input type="text"
                          disabled="true"
                          className="form-control"
                          id="stateAid"
                          value={subsidy + ' €'}
                        />
                      </div>
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <div className="form-group">
                        <label htmlFor="savingPercentage">Economie annuelle</label>
                        <input type="text"
                          disabled="true"
                          className="form-control"
                          id="savingPercentage"
                          value={savingsPercent + ' %'}
                        />
                      </div>

                      <div className="form-group">
                        <label htmlFor="firstYearSaving">Economie</label>
                        <input type="text"
                          disabled="true"
                          className="form-control"
                          id="firstYearSaving"
                          value={savings + " €"}
                        />
                      </div>
                    </div>

                    <div className="form-group" class="mt-3">
                      <label htmlFor="estimatedPrice" style={{ fontWeight: 'bold', fontSize: '18px' }}>Estimation du prix (aide déduite): A partir de {netPrice}  &euro; TTC</label>

                    </div>
                  </div>
                </div>
              )}


              {step === 11 && (
                <div style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <div style={{ backgroundColor: 'rgb(109, 163, 25, 0.76)', color: 'white', textAlign: 'center', padding: '10px', maxWidth: '80%', height: '70%', width: '100%' }}>
                    <h2 class="mt-3 pt-5 fs-1 text-white ">Merci pour votre simulation</h2>
                    <hr class="mt-1" style={{ width: '250px', color: '#ffc107', height: '10px', background: '#ffc107', opacity: 1, margin: '0 auto' }} />
                    <h6 class="mt-3 pt-4 fs-4 text-white">D’ici 24 heures, vous serez contacté(e) par l’un de nos experts solaires pour vous accompagner dans votre projet.</h6>
                  </div>
                </div>
              )}





              <div className="">
                {step === 1 && (
                  <div>
                    {/* <Button
        variant="secondary"
        onClick={handleScreenCapture}
        style={{ marginRight: `10px` }}
      >
        Screen Capture
      </Button> */}

                  </div>

                )}
                {step === 4 && (
                  <Button
                    variant="secondary"
                    onClick={addstep}
                    style={{ marginRight: `10px` }}
                  >
                    Ajout pan
                  </Button>
                )}
                {step === 10 && type == 'Designer' && (
                  <div>
                    {/* <Button
        variant="secondary"
        onClick={handleScreenCapture}
        style={{ marginRight: `10px` }}
      >
        Screen Capture
      </Button> */}

                    <Button
                      variant="secondary"
                      onClick={handleExport}
                      style={{ marginRight: `10px` }}
                    >
                      Export
                    </Button>
                  </div>

                )}
                {step > 0 && type == 'Designer' && (
                  <Button
                    variant="secondary"
                    onClick={handlePrevious}
                    style={{ marginRight: `10px` }}
                  >
                    Retour
                  </Button>
                )}
                {/* {step < 9 && step!=2 && step!=0 && step!=9 && (
                <Button
                  variant="warning"
                  onClick={handleNext}
                  className="text-light w-100"
                > 
                  Prochain
                </Button>
              )} */}

                {/* {step > 1 && (
                <Button
                  // variant="warning"
                  onClick={handleNext}
                  disabled={!mapplace}
                  variant={mapplace ? "warning" : "secondary"}
                  className="text-light w-100"
                >
                  Prochain
                </Button>
              )} */}
                {/* {step == 2 && (
                <Button
                  variant="warning"
                  onClick={handleNext}
                  className="text-light w-100"
                >
                  Mes économies en passant au solaire ?
                </Button>
              )} */}

                {step == 9 && (
                  <Button
                    variant="warning"
                    onClick={handleNext}
                    className="text-light w-100"
                  >
                    J’affine mon projet
                  </Button>
                )}


                {step == 10 && (
                  <Button
                    variant={isChecked1 ? "warning" : "secondary"}
                    disabled={
                      !(
                        isChecked1 &&
                        this.state.phone &&
                        this.state.email &&
                        this.state.surnameLead &&
                        this.state.nameLead
                      )
                    }
                    // disabled={ !isChecked1 && this.state.phone != "" && this.state.email != ""&& this.state.surnameLead != ""
                    // && this.state.nameLead != "" }
                    onClick={() => {
                      handleExport();
                      handleNext();
                    }}
                    // onClick={() => {
                    //   const { isChecked1, phone, email, surnameLead, nameLead } = this.state;
                    //   console.log("Component state:", { isChecked1, phone, email, surnameLead, nameLead });
                    //   handleExport(); 
                    //   handleNext();   
                    // }}
                    className="text-light w-100"
                  >
                    Je valide
                  </Button>
                )}
              </div>

            </div>
          </div>
          {
            step == 1 &&

            <>
            <div className='first-button-wrapper my-1'>
            <Button
              // variant="warning"
              variant={"secondary"}

              onClick={handleFastForward}
              className={` text-light ${(this.state.valueLow ? 'my-3' : '')} `}
            >
              Je ne trouve pas ma maison
            </Button>
              <Button
              // variant="warning"
              variant={"flat"}

              onClick={() => handleNext(1)}
              className={` text-light ${(this.state.valueLow ? 'my-3' : '')} `}
            >
              Je lance l’analyse de mon potentiel solaire
            </Button>
            </div>
            {/* <div className='first-button-wrapper  my-1'> 
            </div> */}
            </>
          }

          {step === 2 && this.state.thirdStepCopy ? (
            <>
              <label className='big-title' style={{ display: `${(this.state.thirdStepCopy ? 'block' : 'none')}` }}>{`Nous n’avons pas réussi à analyser votre toiture !
Donnez-nous ses caractéristiques :`}
             </label>
              <div className='voter-box-grid-main'>
                <label className='label-class align-self-start'>Quelle est la taille de votre toiture ?
                </label>
                <div className='voter-box-grid w-100 d-flex'>
                  <div className='voter-box'>
                    <Button
                      variant=""
                      onClick={() => handleButtonClick('petite',0)}
                      className={`voter-box-btn ${this.state.activeButtonRow1 === 'petite' ? 'voter-box-btn-red' : 'voter-box-btn-black'}`}
                    >
                      <img src={smallFace} className='img-fluid img-fluid-auto' />
                      {/* <img src={require('../assets/image_icon_Screen/smallFace.svg')} className='img-fluid img-fluid-auto' /> */}
                      <div className={`voter-box-text ${this.state.activeButtonRow1 === 'petite' ? ' text-danger' : ' text-dark'}`}> <span>Petite</span><span>&lt; 20 m2</span></div>
                    </Button>
                  </div>
                  <div className='voter-box'>
                    <Button
                      variant=""
                      onClick={() => handleButtonClick('moyenne',1)}
                      className={`voter-box-btn ${this.state.activeButtonRow1 === 'moyenne' ? 'voter-box-btn-red' : 'voter-box-btn-black'}`}
                    >
                      <img src={mediumFace} className='img-fluid img-fluid-auto' />
                      {/* <img src={require('../assets/image_icon_Screen/mediumFace.svg')} className='img-fluid img-fluid-auto' /> */}
                      <div className={`voter-box-text ${this.state.activeButtonRow1 === 'moyenne' ? ' text-danger' : ' text-dark'}`}><span>Moyenne</span><span>20 - 40 m2</span></div>
                    </Button>
                  </div>
                  <div className='voter-box'>
                    <Button
                      variant=""
                      onClick={() => handleButtonClick('grande',2)}
                      className={`voter-box-btn ${this.state.activeButtonRow1 === 'grande' ? 'voter-box-btn-red' : 'voter-box-btn-black'}`}
                    >
                        <img src={bigFace} className='img-fluid img-fluid-auto' />
                      {/* <img src={require('../assets/image_icon_Screen/bigFace.svg')} className='img-fluid img-fluid-auto' /> */}
                      <div className={`voter-box-text ${this.state.activeButtonRow1 === 'grande' ? ' text-danger' : ' text-dark'}`}> <span>Grande</span><span>40 - 60 m2</span></div>
                    </Button>
                  </div>
                  <div className='voter-box'>
                    <Button
                      variant=""
                      onClick={() => handleButtonClick('tres_grande',3)}
                      className={`voter-box-btn ${this.state.activeButtonRow1 === 'tres_grande' ? 'voter-box-btn-red' : 'voter-box-btn-black'}`}
                    >
                      <img src={verybigFace} className='img-fluid img-fluid-auto' />
                      {/* <img src={require('../assets/image_icon_Screen/verybigFace.svg')} className='img-fluid img-fluid-auto' /> */}
                      <div className={`voter-box-text ${this.state.activeButtonRow1 === 'tres_grande' ? ' text-danger' : ' text-dark'}`}><span>Très Grande</span><span>60 - 350 m2</span></div>
                    </Button>
                  </div>
                </div>
              </div>
              <div className='voter-box-grid-main2'>
                <label className='label-class align-self-start'>Quelle est l’orientation de votre toiture ?
                </label>
                <div className='voter-box-grid2'>
                  <div className='voter-box2'>
                    <Button
                      variant=""
                      onClick={() => handleButtonClick2('southF',0)}
                      className={`voter-box-btn2 ${this.state.activeButtonRow2 === 'southF' ? 'voter-box-btn-red2' : 'voter-box-btn-black2'}`}
                      style={{ }} // Height set to 180px
                    >
                       <img src={SouthFace} className='fase-img' />
                      {/* <img src={require('../assets/image_icon_Screen/SouthFace.png')} className='fase-img' /> */}
                      <div className={`voter-box-text2 ${this.state.activeButtonRow2 === 'southF' ? ' text-danger' : ' text-dark'}`}>Sud</div>
                    </Button>
                  </div>
                  <div className='voter-box2'>
                  <Button
                      variant=""
                      onClick={() => handleButtonClick2('southEastF',1)}
                      className={`voter-box-btn2 ${this.state.activeButtonRow2 === 'southEastF' ? 'voter-box-btn-red2' : 'voter-box-btn-black2'}`}
                      // Height set to 180px
                    >
                      <img src={SouthEastFace} className='fase-img' />
                      {/* <img src={require('../assets/image_icon_Screen/SouthEastFace.png')} className='fase-img' /> */}
                      <div className={`voter-box-text2 ${this.state.activeButtonRow2 === 'southEastF' ? ' text-danger' : ' text-dark'}`}><span>Sud-Est ou <br /> Sud-Ouest</span></div>
                    </Button>
                  </div>
                  <div className='voter-box2'>
                  <Button
                      variant=""
                      onClick={() => handleButtonClick2('westEastF',2)}
                      className={`voter-box-btn2 ${this.state.activeButtonRow2 === 'westEastF' ? 'voter-box-btn-red2' : 'voter-box-btn-black2'}`}
                      // Height set to 180px
                    >
                      <img src={WestEastFace} className='fase-img' />
                      {/* <img src={require('../assets/image_icon_Screen/WestEastFace.png')} className='fase-img' /> */}
                      <div className={`voter-box-text2 ${this.state.activeButtonRow2 === 'westEastF' ? ' text-danger' : ' text-dark'}`}> Est/Ouest</div>
                    </Button>
                  </div>
                  <div className='voter-box2'>
                  <Button
                      variant=""
                      onClick={() => handleButtonClick2('northF',3)}
                      className={`voter-box-btn2  ${this.state.activeButtonRow2 === 'northF' ? 'voter-box-btn-red2' : 'voter-box-btn-black2'}`}
                       // Height set to 180px
                    >
                      <img src={NorthFace} className='fase-img' />
                      {/* <img src={require('../assets/image_icon_Screen/NorthFace.png')} className='fase-img' /> */}
                      <div className={`voter-box-text2 ${this.state.activeButtonRow2 === 'northF' ? ' text-danger' : ' text-dark'}`}><span>Orienté Nord ou</span><span> très ombragée</span></div>
                    </Button>
                  </div>
                  <div className='voter-box2'>
                    <Button
                      variant=""
                      onClick={() => handleButtonClick2('flatRoof',4)}
                      className={`voter-box-btn2  ${this.state.activeButtonRow2 === 'flatRoof' ? 'voter-box-btn-red2' : 'voter-box-btn-black2'}`}
                      // Height set to 180px
                    >
                      <img src={FlatRoof} className='fase-img' />
                      {/* <img src={require('../assets/image_icon_Screen/FlatRoof.png')} className='fase-img' /> */}
                      <div className={`voter-box-text2 ${this.state.activeButtonRow2 === 'flatRoof' ? ' text-danger' : ' text-dark'}`}><span>Toit plat</span></div>
                    </Button>
                  </div>
                  <div className='voter-box2'>
                  <Button
                      variant=""
                      onClick={() => handleButtonClick2('doNot',5)}
                      className={`voter-box-btn2  ${this.state.activeButtonRow2 === 'doNot' ? 'voter-box-btn-red2' : 'voter-box-btn-black2'}`}
                      // Height set to 180px
                    >
                      <img src={DontKnowFace} className='fase-img' />
                      {/* <img src={require('../assets/image_icon_Screen/DontKnowFace.png')}className='fase-img' /> */}
                      <div className={`voter-box-text2 ${this.state.activeButtonRow2 === 'doNot' ? ' text-danger' : ' text-dark'}`}>
                        <span>Je ne sais pas</span></div>
                    </Button>
                  </div>
                </div>
              </div>

              <div className='voter-box-grid-main2 my-2'>
                <Button
                  
                  onClick={() => handleNext(2)}
                  disabled={!this.state.activeButtonRow2 || !this.state.activeButtonRow1}
                  variant={(this.state.activeButtonRow2 && this.state.activeButtonRow1? "flat" : "secondary")}
                  className={`text-light w-100`}
                >
                  Mes économies en passant au solaire ?
                </Button>
              </div>


            </>
          ) : (
            step === 2 && this.state.thirdStepCopy === false && this.state.fourthStepShadow == false && (
              <div className='sui-button-wrapper'>
                <div className='button-wrapper'>
                <Button
                  variant={'secondary'}
                  onClick={handleFastForwardSecond}
                  className={`text-light flex-stretch h-100`}
                >
                  Ce n’est pas ma maison
                </Button>
                </div>
                <div className='button-wrapper'>
                <Button

                  disabled={!this.state.maxRoof}
                  variant={(this.state.maxRoof? "flat" : "secondary")}
                  onClick={handleNextKO}
                  className={`text-light flex-stretch h-100`}
                >
                  Suivant
                </Button>
                </div>
              </div>
            )
          )}

          {
            step === 2 && this.state.fourthStepShadow === true && !this.state.secondLoader && (
              <>
              <div className='bonne-sec'>
                <div className='text-center'>
                <label className='big-title1'>
                  {`
Bonne nouvelle ! `}
                </label>
                </div>
                <label className='label-class w-100 mx-auto mb-3 label-col'>
                  <span>{`Votre maison va réaliser des économies`}</span>
                  <span>{`d'énergie sur la base de l'analyse suivante`}</span>
                </label>

                <div className='two-col-row'>
                  <div className='two-col'><label htmlFor="sunshinePerYear" className='label-class'>Productible annuel </label>
                    <input
                      type="text"
                      disabled
                      className="form-control"
                      id="sunshinePerYear"
                      value={this.state.maxRoof && !this.state.isManual ? Math.round(this.state.maxRoof.productible)+' kWh / kWc ' : this.state.manualProductible + ' kWh / kWc '}
                    /// Set your default or empty value here
                    /></div>
                  <div className='two-col'><label htmlFor="surface" className='label-class'>Surface exploitable </label>
                    <input
                      type="text"
                      disabled
                      className="form-control"
                      id="surface"
                      value={this.state.maxRoof && !this.state.isManual?Math.round(this.state.maxRoof.stats.areaMeters2)+ ' m2' :this.state.areaMeters2Manual  + ' m2'}
                    />
                  </div>
                </div>
                {/* <Row className="mb-2">
                    <Col></Col>
                    <Col></Col>
                    </Row> */}
                 <div className='two-col-row'>
                  <div className='two-col'><label htmlFor="orientation" className='label-class'>Orientation (Sud = 0°) </label>
                    <input
                      type="text"
                      disabled
                      className="form-control"
                      id="orientation"
                      value={(this.state.maxRoof && this.state.maxRoof.azimuthDegrees < 1 && this.state.maxRoof.azimuthDegrees >= 0)
                        ? 0 + ' °' : this.state.maxRoof && !this.state.isManual?Math.round((this.state.maxRoof.azimuthDegrees - 180)) + ' °':this.state.orientationManual + ' °'}
                    /></div>
                  <div className='two-col'><label htmlFor="pitch" className='label-class'>Inclinaison </label>
                    <input
                      type="text"
                      className="form-control"
                      id="pitch"
                      disabled
                      value={this.state.maxRoof && !this.state.isManual?Math.round(this.state.maxRoof.pitchDegrees)+ ' °' :this.state.inclinaisonManual+ ' °'}
                    />
                  </div>
                </div>
                <div className='bonne-btn-sec'>
                  <Button
                    variant="flat"
                    onClick={() => handleNext(3)}
                    className={`text-light w-100`}
                  >
                    Je calcule mes économies avec le solaire
                  </Button>
                </div>
                {/* <Row className="mb-2">
                  <Col></Col>
                  <Col></Col>
                </Row> */}
                </div>
              </>
            )
          }


          {
            step === 3 && !this.state.secondLoader && (
              <>
            
              <div class='table-wrapper'>
    <table class="custom-table">
        <thead>
            <tr class='t-head'>
                <th class="t-non-background"></th>
                <td class="t-head-item"><label class='table-label'><span>Offre</span>
                    accessibilité</label>
                </td>
                <td class="t-head-item"><label class='table-label'><span>Offre</span><span>rendement renforcé</span></label>
                </td>
            </tr>
        </thead>
        <tbody>
          <tr className='d-none'></tr>
          <tr className='d-auto-none'>
              <td colspan="2">
                <div class='text-wrapper'>
                    <label class='table-label '>Prix d’achat TTC </label>
                    <p class='m-0 small-txt '>(aide déduite*)</p>
                </div>
              </td>
          </tr>
            <tr>
                <th scope="row" class='t-non-background'>
                    <div class='text-wrapper'>
                        <label class='table-label'>Prix d’achat TTC </label>
                        <p class='m-0 small-txt'>(aide déduite*)</p>
                    </div>
                </th>
               
                <td class=' px-3'>
                    
                    <div class='main-wrapper'> <label class='table-label text-danger big-label'>à partir de {minimumSimulation?(Math.floor(minimumSimulation.netPrice).toLocaleString('fr-FR')):0} €</label> <p class='small-txt m-0'>(Aide de {minimumSimulation?(minimumSimulation.subsidy.toLocaleString('fr-FR')):0} €*)</p></div>
                </td>
                <td class=' px-3'>
                    <div class='main-wrapper'> <label class='table-label text-danger big-label'>à partir de {recommendedSimulation?(Math.floor(recommendedSimulation.netPrice).toLocaleString('fr-FR')):0} €</label> <p class='small-txt m-0'>(Aide de {recommendedSimulation?(recommendedSimulation.subsidy.toLocaleString('fr-FR')):0} €*)</p></div>
                </td>
            </tr>
            <tr className='d-none'></tr>
            <tr className='d-auto-none'><td colSpan={2}>
            <div class='text-wrapper'>
                        <label class='table-label '><span>Puissance</span><span>
                            Nombre de panneaux</span>
                        </label>
                    </div>
              </td></tr>
            <tr>
                <th scope="row" class='t-non-background'>
                    <div class='text-wrapper'>
                        <label class='table-label '><span>Puissance</span><span>
                            Nombre de panneaux</span>
                        </label>
                    </div>
                </th>
                <td class=' px-3'>
            
                    
                    <div class='main-wrapper'> 
                        <label class='table-label big-label'><span>{minimumSimulation?(minimumSimulation.recommandedPower):0} KWc</span><span>{minimumSimulation?minimumSimulation.recommandedNbrPannels:0} panneaux</span></label>
                    </div>
                </td>
                <td class='px-3'>
                    <label class='table-label big-label'><span>{recommendedSimulation?recommendedSimulation.recommandedPower:0} kWc</span><span>{recommendedSimulation?recommendedSimulation.recommandedNbrPannels:0} panneaux</span></label></td>
            </tr>
            <tr className='d-none'></tr>
            <tr className='d-auto-none'>
              <td colSpan={2}>
                <div class='text-wrapper'>
                        <label class='table-label '><span>Economies  d’électricité</span><span>
                            dès la 1ere année*</span>
                        </label>
                    </div>
              </td>
            </tr>
            <tr>
                <th scope="row" class='t-non-background'>
                    <div class='text-wrapper'>
                        <label class='table-label '><span>Economies  d’électricité</span><span>
                            dès la 1ere année*</span>
                        </label>
                    </div>
                </th>
                <td class=' px-3'>
                    <div class='main-wrapper'> <label class='table-label text-danger big-label'>{minimumSimulation?(minimumSimulation.savingsFirstYear.toLocaleString('fr-FR')):0} €</label><p class='small-txt text-danger m-0'>(- {minimumSimulation?minimumSimulation.savingsPercent:0} % sur la facture)</p></div>
                </td>
                <td class=' px-3'>
                    <div class='main-wrapper'> <label class='table-label text-danger big-label'>{recommendedSimulation?(recommendedSimulation.savingsFirstYear.toLocaleString('fr-FR')):0} €</label><p class='small-txt text-danger m-0'>(- {recommendedSimulation?recommendedSimulation.savingsPercent:0} % sur la facture)</p></div>
                </td>
            </tr>
            <tr className='d-none'></tr>
            <tr className='d-auto-none'><td colSpan={2}>
            <div class='text-wrapper'>
                        <label class='table-label '><span>Economies d’électricité</span><span>sur 25 ans*</span></label>

                    </div></td></tr>
            <tr>
                <th scope="row" class='t-non-background'>
                    <div class='text-wrapper'>
                        <label class='table-label '><span>Economies d’électricité</span><span>sur 25 ans*</span> </label>
                    </div>
                </th>
                <td class=''>
                  {minimumSimulation?(Math.floor(minimumSimulation.savings25Years).toLocaleString('fr-FR')):0} €
                </td>
                <td class=''>
                  {recommendedSimulation?(Math.floor(recommendedSimulation.savings25Years).toLocaleString('fr-FR')):0} €
                </td>
            </tr>
            <tr className='d-none'></tr>
            <tr className='d-auto-none'><td colSpan={2}>                <div class='text-wrapper'>
                        <label class='table-label '><span>Votre investissement</span><span>amorti dès *</span></label>
                    </div></td></tr>
            <tr>
                <th scope="row" class='t-non-background'>
                    <div class='text-wrapper'>
                        <label class='table-label '><span>Votre investissement</span><span>amorti dès *</span></label>
                    </div>
                </th>
                <td class=''>
                  {minimumSimulation?minimumSimulation.amortizationTime:0} ans</td>
                <td class=''>
                  {recommendedSimulation?recommendedSimulation.amortizationTime:0} ans</td>


            </tr>
            <tr className='d-none'></tr>
            <tr className='d-auto-none'><td colSpan={2}>
            <div class='text-wrapper'>
                        <label class='table-label '><span>Mensualité</span><span>si achat financé</span></label>
                        <p class='m-0 small-txt '> <i>(* Financement sur 15 ans TAEG 6.39 % )</i></p>
                    </div></td></tr>
            <tr>
                <th scope="row" class='t-non-background'>
                    <div class='text-wrapper'>
                        <label class='table-label '><span>Mensualité</span><span>si achat financé</span></label>
                        <p class='m-0 small-txt ' style={{fontweight: 400}}> <i>(* Financement sur 15 ans TAEG 6.39 % )</i></p>
                    </div>
                </th>

                <td class=''>
                  {minimumSimulation?(Math.floor(minimumSimulation.monthlyPayment).toLocaleString('fr-FR')):0} € / mois</td>
                <td class=''>
                  {recommendedSimulation?(Math.floor(recommendedSimulation.monthlyPayment).toLocaleString('fr-FR')):0} € / mois</td>
            </tr>
            <tr>
                <th scope="row" class='t-non-background'>
                    <div class='text-wrapper'>

                    </div>
                </th>
                <td colSpan={2} class='text-center t-non-background'>              <Button
                    variant="flat"
                    onClick={() => handleNext(4)}
                    class={`text-light w-100`}
                >
                    J’affine mon choix avec un conseiller
                </Button></td>
            </tr>
            <tr>
                <th scope="row" class='t-non-background'>
                    <div class='text-wrapper'>

                    </div>
                </th>
                <td colSpan={2} class='text-center '>
                    <div class='d-flex align-items-center justify-content-between w-100'>
                        <p class='m-0 small-txt'>* Estimation faite sur la base d’hypothèses de calculs </p>
                        <button class='question-btn' onClick={handleModal}>
                            <img src={questionsvg} /></button>
                        {/* <img src={require('../assets/image/question.svg')} /> */}
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
</div>

              </>
            )
          }

          {
            step === 4 && !this.state.secondLoader && (

              <div className="contacte-form">
                <label className='label-class w-100 mx-auto mb-3 label-col'>
                  <span className='title'>{`Dernière étape pour obtenir votre devis gratuit. `}</span>
                  <span>{`On vous contacte très bientôt !`}</span>
                </label>

                <div className='two-col-row' style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <div className="two-col">
                    <label htmlFor="nom" className='label-class'>Nom*</label>
                    <input
                      type="text"
                      className="form-control"
                      id="nom"
                      name="nom"
                      placeholder='nom'
                      value={this.state.nameLead}
                      onChange={(e) => this.setState({ nameLead: e.target.value })}
                    // onChange={(e) => {
                    //   const selectedValue = e.target.value;
                    //   this.setState({nameLead:selectedValue})
                    // }}
                    //  onChange={this.handleChange}
                    />
                  </div>
                  <div className="two-col">
                    <label htmlFor="prenom" className='label-class'>Prénom*</label>
                    <input
                      type="text"
                      className="form-control"
                      id="prenom"
                      name="prenom"
                      placeholder='prenom'
                      value={this.state.surnameLead}
                      onChange={(e) => {
                        const selectedValue = e.target.value;
                        this.setState({ surnameLead: selectedValue })
                      }}
                    />
                  </div>
                </div>

                <div className='two-col-row' style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <div className="two-col">
                    <label htmlFor="email" className='label-class'>Email*</label>
                    <input
                      type="email"
                      className="form-control no-shadow"
                      id="email"
                      name="email"
                      placeholder='abc@gmail.com'
                      value={this.state.email}
                      onChange={this.handleChange}
                      />
                      {this.state.emailError && <div className='d-flex align-items-center justify-content-start'><p style={{ float: "left" }} className='text-danger m-0'><span className='text-danger m-0' style={{ fontWeight: "bold", fontSize: "25px" }}>&#8594;</span> eMail invalide</p></div> }
                  </div>
                  <div className="two-col">
                    <label htmlFor="telephone" className='label-class'>Téléphone* </label>
                    <input
                      type="number"
                      className="form-control no-shadow"
                      id="telephone"
                      
                      name="telephone"
                      placeholder='06 18 65 65 89'
                      value={this.state.phone}
                      onChange={(e) => {
                        const selectedValue = e.target.value;
                        this.setState({ phone: selectedValue })
                      }}
                    />
                    {this.state.phone.length!==10 &&this.state.phone.length>0 && <div className='d-flex align-items-center justify-content-start'><p style={{ float: "left" }} className='text-danger m-0'><span className='text-danger m-0' style={{ fontWeight: "bold", fontSize: "25px" }}>&#8594;</span> numéro de téléphone invalide</p></div> }
                  </div>
                </div>

                <div className="one-col">
                  <label htmlFor="echeance" className='label-class'>Quand souhaitez vous commencer votre projet solaire*  ?</label>
                  <div className='droup-select'>
                    
                      <select
                        className="form-control no-shadow"
                        name="echeance"
                        value={this.state.projectTiming}
                        onChange={(e) => {
                          const selectedValue = e.target.value;
                          this.setState({ projectTiming: selectedValue })
                        }}
                      >
                        <option value="">Choisissez une échéance</option>
                        <option value="3mois">Dans les 3 mois</option>
                        <option value="6mois">Dans les 6 mois</option>
                        <option value="1an">Dans l'année</option>
                        <option value="inconnu">Je ne sais pas</option>
                      </select>
                   
                  </div>
                </div>

                {/* <div class="mt-5">
                  <div style={{display:'flex'}}>  <input type="checkbox" style={{ marginRight: '5px' ,width:'20px'}} />
                    <div>
                        J’accepte que mes données soient transmises pour faire une étude détaillée de mon projet et finaliser mon éligibilité
                    </div>
                  </div>
              </div> */}
              <div className='info'>* : informations obligatoires à nous transmettre</div>
                <div class="checkbox-sec">
                  <div className='checkbox-inr'>
                    <input
                      type="checkbox"
                      checked={isChecked}
                      className='custom-checkbox'
                      onChange={this.handleCheckboxChange}
                    />
                    <label>
                      En cliquant sur « Je valide", j'indique accepter les <a href='https://bd-renovation-energetique.fr/cgu/'>Conditions Générales d'Utilisation</a> ("CGU") et donne mon accord pour le traitement de mes données. Je comprends que les informations marquées par un astérisque sont obligatoires afin de pouvoir traiter ma demande. Elles sont à destination exclusivement d’Ynergie, partenaire de Brico Dépôt. Pour plus d’informations sur nos traitements de vos données et sur vos droits à cet égard, merci de consulter notre <a href='https://bd-renovation-energetique.fr/politique-de-
confidentialite/'>Politique de confidentialité</a>
                    </label>
                  </div>
                </div>


                <div class="checkbox-sec">
                  <div className='checkbox-inr'>
                  <input
                    type="checkbox"
                    className='custom-checkbox'
                    checked={isChecked1}
                    onChange={this.handleCheckboxChange1}
                  />
                   <label>J'accepte de recevoir les communications de Brico Dépôt par voie électronique. Mes données personnelles d'identification et de contact seront traitées dans le cadre de la politique de protection des données disponibles sur le site <a href='https://www.bricodepot.fr/'>www.bricodepot.fr</a>
                    </label>
                </div>
                </div>
                <Button
                  onClick={handleFormSubmit}
                  disabled={isFormInvalid  || this.state.isFormSubmit}
                  className={`text-light w-100`}
                  variant={isFormInvalid?"secondary":"flat"}
                >
                  Je valide
                </Button>
              </div>







            )
          }

          {
            this.state.showModal && (
              <>
              <Modal show={this.state.showModal} onHide={this.handleClose} size='xl' >
                
                  <Modal.Body className='question-modal'>
                    <div className='que-header' onClick={this.handleClose} >
                      <div className='close-icon' style={{ cursor: 'pointer' }}><i className="fas fa-close text-light "></i></div></div>
                    <div>
                   
                      <div className='content-wrapper'>
                        <h3>Nos estimations d'économie sont indicatives et non contractuelles </h3>
                        <p>Le calcul des économies est la différence entre le montant de la facture d’électricité (scénario sans passer au solaire) subissant les augmentations du prix marché pour une consommation en kWh constante, et le montant de la facture d'électricité avec les économies réalisées avec le solaire (autoconsommation + revente de surplus).</p>
                        <p>
                        Les économies sur 25 ans incluent la prime à l’autoconsommation mais ne tiennent pas compte du montant d'achat de la centrale solaire. 
                        </p>
                        <p>Les hypothèses suivantes sont prises :</p>
                        <ul className='qm-list'>
                          <li>Famille de 4 personnes (2 adultes + 2 enfants)</li>
                          <li>Présence en semaine : Télétravail 2 jours</li>
                          <li>Équipements énergivores :
                            <ul>
                              <li>Chauffage de l’eau chaude sanitaire (fonctionnant en heure creuse) : Ballon électrique de 200 L</li>
                              <li>Chauffage et climatisation avec une pompe à chaleur air-eau</li>
                              <li>Pas de piscine, spa ou sauna</li>
                              <li>Pas de borne de recharge pour véhicule électrique</li>
                              <li>Si inclinaison non détectée automatiquement, par défaut 20°</li>
                            </ul>
                          </li>
                          <li>Augmentation du prix de l’électricité : 5% par an pendant les 10 prochaines années, puis 3% pendant les années suivantes</li>
                          <li>Perte de productivité des panneaux de 0,5% par an</li>
                        </ul>
                        <p>Le montant de la Prime à l’autoconsommation et le prix de rachat des kWh réinjectés dans le réseau dépendent de la date de demande complète de raccordement auprès du gestionnaire de réseau Enedis ou ELD. Les valeurs prises en compte sont celles communiquées par la CRE au jour de la simulation.</p>
                        <p>Concernant la mensualité de financement : prêt affecté avec un TAEG de 6,39% et un taux d’assurance de 2,74%.</p>
                        <p>Les calculs sont établis en fonction des renseignements fournis lors de la simulation, et dépendent notamment des conditions d’ensoleillement réel et des choix et pratiques quotidiennes d’utilisation des équipements électriques dans le foyer. Une visite technique sera probablement nécessaire pour confirmer la faisabilité technique du projet et les projections d’économies.</p>
                      </div>
                    </div>
                  </Modal.Body>
 
      </Modal></>
            )
          }


        </div>
      </div>
      </div>
    );
  }
}

export default Autocomplete;
