import React, { useEffect } from 'react';
import './App.css';
import AutoComplete from './component/Autosomp';

function App() {
  

  return (
    <div>
      
    
        <AutoComplete  />
    
    </div>
  );
}

export default App;
